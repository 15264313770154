<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'portal' ? 'active' : ''" @click="switchTab('portal')">Main</li>
                <li :class="tab === 'login' ? 'active' : ''" @click="switchTab('login')">Login Screen</li>
                <li :class="tab === 'seo' ? 'active' : ''" @click="switchTab('seo')">SEO</li>
                <li :class="tab === 'chat' ? 'active' : ''" @click="switchTab('chat')">Chat</li>
                <li :class="tab === 'footer' ? 'active' : ''" @click="switchTab('footer')">Footer</li>
                <li :class="tab === 'delivery' ? 'active' : ''" @click="switchTab('delivery')">Delivery Message</li>
            </ul>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <Form @submit="handleSubmit" v-slot="{ errors }" class="client-portal-setting-form" ref="client-portal-setting-form">
                    <div class="content_area">
                        <div class="content_wpr" v-if="tab === 'portal'">
                            <div class="section_content w-100" @click="goTo('hdrsection')" ref="hdrsetting">
                                <div class="section_header">
                                    <h2>Header Settings</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Header Background Color</label>
                                                <Field autocomplete="off" name="header_bgcolor" v-model="form.header_bgcolor" type="text" label="element color">
                                                    <color-picker v-model="form.header_bgcolor" :classes="{ 'has-error': errors.header_bgcolor }" />
                                                </Field>
                                                <ErrorMessage name="header_bgcolor" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Header Text Color</label>
                                                <Field autocomplete="off" name="header_textcolor" v-model="form.header_textcolor" type="text" label="element color">
                                                    <color-picker v-model="form.header_textcolor" :classes="{ 'has-error': errors.header_textcolor }" />
                                                </Field>
                                                <ErrorMessage name="header_textcolor" class="text-danger" />
                                            </div>
                                        </div>
                                        <h3 class="sub_header">Header Logo</h3>
                                        <image-library v-model="form.logo" image-type="public-logo" upload-text="Logo" />
                                        <h3 class="sub_header mt-5">Header Author Image</h3>
                                        <image-library v-model="form.author" image-type="avatar" upload-text="Logo" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('bnrsection')" ref="bnrsetting">
                                <div class="section_header">
                                    <h2>Cover Setting</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <label for="dashboard-cover" class="switch_option capsule_btn border-0">
                                            <h5 class="large">Display Dashboard Cover <span>Upload a small screen cover for a better view</span></h5>
                                            <input type="checkbox" id="dashboard-cover" :true-value="1" :false-value="0" v-model="form.display_cover" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="cover_type" v-if="form.display_cover">
                                            <h3 class="sub_header">Cover Type</h3>
                                            <ul class="type_list">
                                                <li>
                                                    <label for="clr_text">
                                                        <input type="radio" id="clr_text" :value="1" v-model="form.cover_type" hidden>
                                                        <img src="@/assets/images/background.svg" alt="">
                                                        <h5>Color + Text</h5>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="img_text">
                                                        <input type="radio" id="img_text" :value="2" v-model="form.cover_type" hidden>
                                                        <img src="@/assets/images/picture.svg" alt="">
                                                        <h5>Image + Text</h5>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="img_only">
                                                        <input type="radio" id="img_only" :value="3" v-model="form.cover_type" hidden>
                                                        <img src="@/assets/images/picture.svg" alt="">
                                                        <h5>Image Only</h5>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-if="form.display_cover && form.cover_type !== 3">
                                            <div class="setting_wpr mt-3">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Headline <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                            <Field autocomplete="nofill" type="text" name="headline" v-model="form.headline" placeholder="Compelling Headline Goes Here" />
                                                        </div>
                                                        <ErrorMessage name="headline" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div v-if="headlineSetting">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Family</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                            </div>
                                                        </div>
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Weight</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Size</label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Color</label>
                                                            <Field autocomplete="off" name="headline_setting_font_color" v-model="form.headline_setting.font_color" type="text" label="element color">
                                                                <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.headline_setting_font_color }" />
                                                            </Field>
                                                            <ErrorMessage name="headline_setting_font_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="cover_type mt-4 mb-3 pb-4">
                                                        <label for="headline_shadow" class="switch_option capsule_btn">
                                                            <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                            <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.headline_setting.has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Sub Headline <button type="button" class="px-1" @click="subheadlineSetting = !subheadlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                        <div class="field_wpr">
                                                            <Field autocomplete="nofill" type="text" name="sub_headline" v-model="form.description" placeholder="Enter text here" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="subheadlineSetting">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Family</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                            </div>
                                                        </div>
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Weight</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Size</label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Color</label>
                                                            <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                            </Field>
                                                            <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="cover_type mt-4 mb-3 pb-4">
                                                        <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                            <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                            <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.subheadline_setting.has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.display_cover && form.cover_type !== 1">
                                            <h3 class="sub_header">Cover Image</h3>
                                            <image-library v-model="form.cover" image-type="public-cover" upload-text="Cover" />
                                            <div class="cover_type mt-5 mb-2 py-2">
                                                <label for="small_image" class="switch_option capsule_btn">
                                                    <h5 class="large">Small Screen Image</h5>
                                                    <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.has_small_screen_cover" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <image-library v-if="form.has_small_screen_cover" v-model="form.small_cover" image-type="public-cover-small" upload-text="Cover" />
                                            </div>
                                            <label for="overlay" class="switch_option capsule_btn">
                                                <h5 class="large">Add Image Overlay</h5>
                                                <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.has_cover_overlay" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.has_cover_overlay">
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Overlay Color</label>
                                                        <Field autocomplete="off" name="overlay_color" v-model="form.overlay_color" type="text" label="element color">
                                                            <color-picker v-model="form.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                        </Field>
                                                        <ErrorMessage name="overlay_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Overlay Opacity</label>
                                                        <div class="field_wpr">
                                                            <Field autocomplete="nofill" name="overlay_opacity" v-model="form.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="element color" />
                                                        </div>
                                                        <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.display_cover && form.cover_type === 1" class="mt-2">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Background Color</label>
                                                    <Field autocomplete="off" name="cover_background_color" v-model="form.cover_background_color" type="text" label="element color">
                                                        <color-picker v-model="form.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                                    </Field>
                                                    <ErrorMessage name="cover_background_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('navsection')" ref="navsetting">
                                <div class="section_header">
                                    <h2>Manage Navbar</h2>
                                </div>
                                <div class="color_container mt-2 p-0">
                                    <ul class="navbar_setting">
                                        <li>
                                            PlayBooks
                                            <!-- <button type="button" class="edit_btn">Edit</button> -->
                                            <label for="playbooks" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="playbooks" v-model="form.playbook_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            Pages
                                            <!-- <button type="button" class="edit_btn">Edit</button> -->
                                            <label for="pages" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="pages" v-model="form.page_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            Forms
                                            <!-- <button type="button" class="edit_btn">Edit</button> -->
                                            <label for="forms" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="forms" v-model="form.form_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            Calendar
                                            <!-- <button type="button" class="edit_btn">Edit</button> -->
                                            <label for="calendar" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="calendar" v-model="form.calendar_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            Charts
                                            <!-- <button type="button" class="edit_btn">Edit</button> -->
                                            <label for="progress" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="progress" v-model="form.progress_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li>
                                            Workouts
                                            <!-- <button type="button" class="edit_btn">Edit</button> -->
                                            <label for="workouts" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="workouts" v-model="form.workout_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('navsection')">
                                <div class="section_header">
                                    <h2>Navigation Color</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="group_item">
                                        <label class="input_label">Selected Color</label>
                                        <Field autocomplete="off" name="portal_tab_bg_color" v-model="form.portal_tab_bg_color" type="text" label="element color">
                                            <color-picker v-model="form.portal_tab_bg_color" :classes="{ 'has-error': errors.portal_tab_bg_color }" />
                                        </Field>
                                        <ErrorMessage name="portal_tab_bg_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('navsection')">
                                <div class="section_header">
                                    <h2>Navigation Text Color</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="group_item">
                                        <label class="input_label">Selected Color</label>
                                        <Field autocomplete="off" name="portal_tab_text_color" v-model="form.portal_tab_text_color" type="text" label="element text color">
                                            <color-picker v-model="form.portal_tab_text_color" :classes="{ 'has-error': errors.portal_tab_text_color }" />
                                        </Field>
                                        <ErrorMessage name="portal_tab_text_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('navsection')">
                                <div class="section_header">
                                    <h2>Title Text Color</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="group_item">
                                        <label class="input_label">Selected Color</label>
                                        <Field autocomplete="off" name="announcement_title_text_color" v-model="form.announcement_title_text_color" type="text" label="element text color">
                                            <color-picker v-model="form.announcement_title_text_color" :classes="{ 'has-error': errors.announcement_title_text_color }" />
                                        </Field>
                                        <ErrorMessage name="announcement_title_text_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('activity')" ref="activity1">
                                <div class="section_header">
                                    <h2>Habit Score</h2>
                                    <label for="has-habit-score" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-habit-score" :true-value="1" :false-value="0" v-model="form.has_habit_score" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2" v-show="form.has_habit_score">
                                    <div class="group_item">
                                        <label class="input_label">Habit Score Color</label>
                                        <Field autocomplete="off" name="habit_score_color" v-model="form.habit_score_color" type="text" label="element color">
                                            <color-picker v-model="form.habit_score_color" :classes="{ 'has-error': errors.habit_score_color }" />
                                        </Field>
                                        <ErrorMessage name="habit_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('activity')" ref="activity2">
                                <div class="section_header">
                                    <h2>Progress Score</h2>
                                    <label for="has-progress-color" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-progress-color" :true-value="1" :false-value="0" v-model="form.has_progress_score" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2" v-show="form.has_progress_score">
                                    <div class="group_item">
                                        <label class="input_label">Progress Score Color</label>
                                        <Field autocomplete="off" name="progress_score_color" v-model="form.progress_score_color" type="text" label="element color">
                                            <color-picker v-model="form.progress_score_color" :classes="{ 'has-error': errors.progress_score_color }" />
                                        </Field>
                                        <ErrorMessage name="progress_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('activity')" ref="activity3">
                                <div class="section_header">
                                    <h2>Portals Logins</h2>
                                    <label for="has-portal-login" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-portal-login" :true-value="1" :false-value="0" v-model="form.has_portal_login" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2" v-show="form.has_portal_login">
                                    <div class="group_item">
                                        <label class="input_label">Portals Logins Color</label>
                                        <Field autocomplete="off" name="login_score_color" v-model="form.login_score_color" type="text" label="element color">
                                            <color-picker v-model="form.login_score_color" :classes="{ 'has-error': errors.login_score_color }" />
                                        </Field>
                                        <ErrorMessage name="login_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('activity')" ref="activity4">
                                <div class="section_header">
                                    <h2>Referrals</h2>
                                    <label for="has-referrals" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-referrals" :true-value="1" :false-value="0" v-model="form.has_referrals" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2" v-show="form.has_referrals">
                                    <div class="group_item">
                                        <label class="input_label">Referrals Color</label>
                                        <Field autocomplete="off" name="referral_score_color" v-model="form.referral_score_color" type="text" label="element color">
                                            <color-picker v-model="form.referral_score_color" :classes="{ 'has-error': errors.referral_score_color }" />
                                        </Field>
                                        <ErrorMessage name="referral_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('announcement')" ref="announsetting">
                                <div class="section_header">
                                    <h2>Announcement Settings</h2>
                                    <label for="announcement" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="announcement" :true-value="1" :false-value="0" v-model="form.has_announcement" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2" v-if="form.has_announcement">
                                    <div class="group_item">
                                        <label class="input_label">Background Color</label>
                                        <Field autocomplete="off" name="announcement_bg_color" v-model="form.announcement_bg_color" type="text" label="background color">
                                            <color-picker v-model="form.announcement_bg_color" :classes="{ 'has-error': errors.announcement_bg_color }" />
                                        </Field>
                                        <ErrorMessage name="announcement_bg_color" class="text-danger" />
                                    </div>
                                    <div class="group_item mt-3">
                                        <label class="input_label">Text Color</label>
                                        <Field autocomplete="off" name="announcement_text_color" v-model="form.announcement_text_color" type="text" label="background color">
                                            <color-picker v-model="form.announcement_text_color" :classes="{ 'has-error': errors.announcement_text_color }" />
                                        </Field>
                                        <ErrorMessage name="announcement_text_color" class="text-danger" />
                                    </div>
                                    <div class="setting_wpr mt-3">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Announcement Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.announcement_title }">
                                                    <Field autocomplete="off" name="announcement_title" v-model="form.announcement_title" type="text" placeholder="ex: Click here to sign up!" rules="required" label="link text" />
                                                </div>
                                                <ErrorMessage name="announcement_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <!-- <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Announcement Title Text Color</label>
                                                <Field autocomplete="off" name="announcement_title_text_color" v-model="form.announcement_title_text_color" type="text" label="background color">
                                                    <color-picker v-model="form.announcement_title_text_color" :classes="{ 'has-error': errors.announcement_title_text_color }" />
                                                </Field>
                                                <ErrorMessage name="announcement_title_text_color" class="text-danger" />
                                            </div>
                                        </div> -->
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Message</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.announcement_message }">
                                                    <Field autocomplete="off" type="textarea" name="announcement_message" v-model="form.announcement_message" :rules="`${form.has_announcement ? 'required' : ''}`" label="message">
                                                        <textarea name="announcement_message" v-model="form.announcement_message" rows="10" cols="30"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="announcement_message" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="announcement_link" class="switch_option capsule_btn p-0 border-0 mt-4 mb-2">
                                        <h5 class="large">Include Link</h5>
                                        <input type="checkbox" id="announcement_link" :true-value="1" :false-value="0" v-model="form.has_announcement_link" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="setting_wpr" v-if="form.has_announcement_link">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Link Text</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.announcement_link_text }">
                                                    <Field autocomplete="off" name="announcement_link_text" v-model="form.announcement_link_text" type="text" placeholder="ex: Click here to sign up!" rules="required" label="link text" />
                                                </div>
                                                <ErrorMessage name="announcement_link_text" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Link Address</label>
                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.announcement_link }">
                                                    <Field autocomplete="off" name="announcement_link" v-model="form.announcement_link" type="text" placeholder="https://superfitcoaching.com " rules="required|url" label="link" />
                                                    <span class="prefix">URL</span>
                                                </div>
                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                <ErrorMessage name="announcement_link" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Link Color</label>
                                                <Field autocomplete="off" name="announcement_link_color" v-model="form.announcement_link_color" type="text" label="background color">
                                                    <color-picker v-model="form.announcement_link_color" :classes="{ 'has-error': errors.announcement_link_color }" />
                                                </Field>
                                                <ErrorMessage name="announcement_link_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('welcomevideo')" ref="videosetting">
                                <div class="section_header">
                                    <h2>Welcome Video Settings</h2>
                                    <label for="welcome_video" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="welcome_video" :true-value="1" :false-value="0" v-model="form.has_welcome_video" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2" v-if="form.has_welcome_video">
                                    <div class="setting_wpr mb-3">
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <label class="input_label">Welcome Title</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="text" name="welcome_video_title" v-model="form.welcome_video_title" placeholder="ex: Welcome Video" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form_grp m-0">
                                        <div class="group_item w-100">
                                            <label class="input_label">Welcome Title Text Color</label>
                                            <Field autocomplete="off" name="welcome_title_text_color" v-model="form.welcome_title_text_color" type="text" label="background color">
                                                <color-picker v-model="form.welcome_title_text_color" :classes="{ 'has-error': errors.welcome_title_text_color }" />
                                            </Field>
                                            <ErrorMessage name="welcome_title_text_color" class="text-danger" />
                                        </div>
                                    </div> -->
                                    <label for="video_url" class="switch_option capsule_btn p-0 border-0">
                                        <h5 class="large">Video Link</h5>
                                        <input type="radio" id="video_url" :value="1" v-model="form.welcome_video_type" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="setting_wpr" v-if="form.welcome_video_type == 1">
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="text" name="welcome_video_link" v-model="form.welcome_video_link" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo" rules="url" label="video link" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label for="embed_video" class="switch_option capsule_btn p-0 mt-4 mb-3 border-0">
                                        <h5 class="large">Embeded Video</h5>
                                        <input type="radio" id="embed_video" :value="2" v-model="form.welcome_video_type" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="setting_wpr" v-if="form.welcome_video_type == 2">
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="textarea" name="welcome_video_embed_code" v-model="form.welcome_video_embed_code" label="embed code">
                                                        <textarea name="welcome_video_embed_code" v-model="form.welcome_video_embed_code" placeholder="embeded code here.."></textarea>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('pintraining')" ref="pinsetting">
                                <div class="section_header">
                                    <h2>Pinned Training</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <label for="pinned-training" class="switch_option capsule_btn p-0">
                                        <h5>Pinned Training</h5>
                                        <input type="checkbox" id="pinned-training" :true-value="1" :false-value="0" v-model="form.pinned_widget" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab === 'login'">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Login URL</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0 mt-2">
                                        <div class="group_item">
                                            <div class="field_wpr has_suffix">
                                                <Field autocomplete="off" type="text" name="portal_login_url" v-model="form.portal_url" placeholder="https://superfitcoaching.com" />
                                                <span class="suffix pointer" @click="handleCopy(form.portal_url)">Copy</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="layout_content w-100">
                                <ul>
                                    <li>
                                        <div class="section_header mb-3">
                                            <h3 class="sub_header m-0">Layout 1</h3>
                                            <label for="layout1" class="switch_option capsule_btn p-0">
                                                <input type="radio" id="layout1" :value="1" v-model="form.login_layout" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <img src="@/assets/images/wireframe-1.svg" :class="form.login_layout === 1 ? 'active' : ''">
                                    </li>
                                    <li>
                                        <div class="section_header mb-3">
                                            <h3 class="sub_header m-0">Layout 2</h3>
                                            <label for="layout2" class="switch_option capsule_btn p-0">
                                                <input type="radio" id="layout2" :value="2" v-model="form.login_layout" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <img src="@/assets/images/wireframe-2.svg" :class="form.login_layout === 2 ? 'active' : ''">
                                    </li>
                                    <li>
                                        <div class="section_header mb-3">
                                            <h3 class="sub_header m-0">Layout 3</h3>
                                            <label for="layout3" class="switch_option capsule_btn p-0">
                                                <input type="radio" id="layout3" :value="3" v-model="form.login_layout" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <img src="@/assets/images/wireframe-3.svg" :class="form.login_layout === 3 ? 'active' : ''">
                                    </li>
                                </ul>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2 class="sub_header m-0">
                                        Login Cover
                                    </h2>
                                    <label for="has-login-cover" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-login-cover" :true-value="1" :false-value="0" v-model="form.login_setting.has_cover" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2 pt-2" v-if="form.login_setting.has_cover">
                                    <div class="setting_wpr">
                                        <div class="cover_type login_cover">
                                            <h3 class="sub_header">Cover Type</h3>
                                            <ul class="type_list">
                                                <li>
                                                    <label for="clr_text">
                                                        <input type="radio" id="clr_text" :value="1" v-model="form.login_setting.cover_type" hidden>
                                                        <img src="@/assets/images/background.svg" alt="">
                                                        <h5>Color + Text</h5>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="img_text">
                                                        <input type="radio" id="img_text" :value="2" v-model="form.login_setting.cover_type" hidden>
                                                        <img src="@/assets/images/picture.svg" alt="">
                                                        <h5>Image + Text</h5>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="img_only">
                                                        <input type="radio" id="img_only" :value="3" v-model="form.login_setting.cover_type" hidden>
                                                        <img src="@/assets/images/picture.svg" alt="">
                                                        <h5>Image Only</h5>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-if="form.login_setting.cover_type !== 3">
                                            <div class="setting_wpr mt-3">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Headline <button type="button" class="px-1" @click="loginHeadlineSetting = !loginHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                            <Field autocomplete="nofill" type="text" name="headline" v-model="form.login_setting.headline" placeholder="Compelling Headline Goes Here" />
                                                        </div>
                                                        <ErrorMessage name="headline" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div v-if="loginHeadlineSetting">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Family</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.login_setting.headline_setting_font_family" v-bind="fontFamily"></multiselect>
                                                            </div>
                                                        </div>
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Weight</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.login_setting.headline_setting_font_weight" v-bind="fontWeight"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Size</label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.login_setting.headline_setting_font_size" min="0" max="100" placeholder="90" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Color</label>
                                                            <Field autocomplete="off" name="headline_setting_font_color" v-model="form.login_setting.headline_setting_font_color" type="text" label="element color">
                                                                <color-picker v-model="form.login_setting.headline_setting_font_color" :classes="{ 'has-error': errors.headline_setting_font_color }" />
                                                            </Field>
                                                            <ErrorMessage name="headline_setting_font_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="cover_type mt-4 mb-3 pb-4 login_cover">
                                                        <label for="headline_shadow" class="switch_option capsule_btn">
                                                            <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                            <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.login_setting.headline_setting_has_shadow" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.login_setting.headline_setting_has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.login_setting.headline_setting_shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.login_setting.headline_setting_shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.login_setting.headline_setting_shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.login_setting.headline_setting_shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.login_setting.headline_setting_shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Sub Headline <button type="button" class="px-1" @click="loginSubheadlineSetting = !loginSubheadlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                                        <div class="field_wpr">
                                                            <Field autocomplete="nofill" type="text" name="sub_headline" v-model="form.login_setting.subheadline" placeholder="Enter text here" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="loginSubheadlineSetting">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Family</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.login_setting.subheadline_setting_font_family" v-bind="fontFamily"></multiselect>
                                                            </div>
                                                        </div>
                                                        <div class="group_item">
                                                            <label class="input_label">Font-Weight</label>
                                                            <div class="field_wpr m-0">
                                                                <multiselect v-model="form.login_setting.subheadline_setting_font_weight" v-bind="fontWeight"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Size</label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.login_setting.subheadline_setting_font_size" min="0" max="100" placeholder="90" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Color</label>
                                                            <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.login_setting.subheadline_setting_font_color" type="text" label="element color">
                                                                <color-picker v-model="form.login_setting.subheadline_setting_font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                            </Field>
                                                            <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="cover_type mt-4 mb-3 pb-4 login_cover">
                                                        <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                            <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                            <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.login_setting.subheadline_setting_has_shadow" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.login_setting.subheadline_setting_has_shadow">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-x</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.login_setting.subheadline_setting_shadow_x" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Offset-y</label>
                                                                    <div class="field_wpr ">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.login_setting.subheadline_setting_shadow_y" min="0" max="100" placeholder="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-3">
                                                                <div class="group_item">
                                                                    <label class="input_label">Blur-Radius</label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.login_setting.subheadline_setting_shadow_blur" min="0" max="100" placeholder="68" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Shadow Color</label>
                                                                    <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.login_setting.subheadline_setting_shadow_color" type="text" label="element color">
                                                                        <color-picker v-model="form.login_setting.subheadline_setting_shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.login_setting.cover_type !== 1">
                                            <h3 class="sub_header">Cover Image</h3>
                                            <image-library v-model="form.login_setting.cover" image-type="public-cover" upload-text="Cover" />
                                            <div class="cover_type mt-5 mb-2 py-2">
                                                <label for="small_login_image" class="switch_option capsule_btn">
                                                    <h5 class="large">Small Screen Image</h5>
                                                    <input type="checkbox" id="small_login_image" :true-value="1" :false-value="0" v-model="form.login_setting.display_small_cover" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="form.login_setting && form.login_setting.display_small_cover" class="upload_image">
                                                    <image-library v-model="form.login_setting.small_cover" image-type="public-cover-small" upload-text="Cover" />
                                                </div>
                                            </div>
                                            <label for="overlay" class="switch_option capsule_btn">
                                                <h5 class="large">Add Image Overlay</h5>
                                                <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.login_setting.has_cover_overlay" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.login_setting.has_cover_overlay">
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Overlay Color</label>
                                                        <Field autocomplete="off" name="overlay_color" v-model="form.login_setting.overlay_color" type="text" label="element color">
                                                            <color-picker v-model="form.login_setting.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                        </Field>
                                                        <ErrorMessage name="overlay_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Overlay Opacity</label>
                                                        <div class="field_wpr">
                                                            <Field autocomplete="nofill" name="overlay_opacity" v-model="form.login_setting.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="element color" />
                                                        </div>
                                                        <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.login_setting.cover_type === 1" class="mt-2">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Background Color</label>
                                                    <Field autocomplete="off" name="cover_background_color" v-model="form.login_setting.cover_background_color" type="text" label="element color">
                                                        <color-picker v-model="form.login_setting.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                                    </Field>
                                                    <ErrorMessage name="cover_background_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Login Banner</h2>
                                </div>
                                <div class="color_container mt-2 pt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Login Text</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.login_title }">
                                                    <Field autocomplete="off" type="text" name="login_title" v-model="form.login_title" placeholder="ex: Secure Client Portal" rules="required" label="login title" />
                                                </div>
                                                <ErrorMessage name="login_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Button Text</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.btn_text }">
                                                    <Field autocomplete="off" type="text" name="btn_text" v-model="form.btn_text" placeholder="ex: Sign In" rules="required" label="button text" />
                                                </div>
                                                <ErrorMessage name="btn_text" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="login_thumbnail" class="switch_option capsule_btn p-0 border-0 mt-2">
                                        <h5 class="large">Login Thumbnail</h5>
                                        <input type="checkbox" id="login_thumbnail" :true-value="1" :false-value="0" v-model="form.display_login_card" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="edit_section mt-2" v-if="form.display_login_card">
                                        <label for="thumb_image" class="switch_option capsule_btn border-0">
                                            <h5 class="large">Image</h5>
                                            <input type="radio" id="thumb_image" :value="1" v-model="form.thumbnail_type" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="upload_image mb-3 mt-3" v-if="form.thumbnail_type === 1">
                                            <image-library v-model="form.login_card" image-type="dashboard-thumb" upload-text="Image" />
                                        </div>
                                    </div>
                                    <div class="edit_section mt-2" v-if="form.display_login_card">
                                        <label for="login_video" class="switch_option capsule_btn p-0 mt-4 mb-3">
                                            <h5 class="large">Login Video</h5>
                                            <input type="checkbox" id="login_video" :true-value="1" :false-value="0" v-model="form.has_login_video" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-if="form.has_login_video" class="border-top py-4">
                                            <label for="thumb_url" class="switch_option capsule_btn p-0 border-0">
                                                <h5 class="large">Video Link</h5>
                                                <input type="radio" id="thumb_url" :value="3" v-model="form.thumbnail_type" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="setting_wpr" v-if="form.thumbnail_type == 3">
                                                <div class="form_grp p-0">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.thumbnail_video_link }">
                                                            <Field autocomplete="off" type="text" name="thumbnail_video_link" v-model="form.thumbnail.url" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo" rules="required|url" label="video link" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="thumbnail_video_link" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <label for="banner_video" class="switch_option capsule_btn p-0 mt-4 mb-3 border-0">
                                                <h5 class="large">Embeded Video</h5>
                                                <input type="radio" id="banner_video" :value="2" v-model="form.thumbnail_type" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="setting_wpr" v-if="form.thumbnail_type == 2">
                                                <div class="form_grp p-0">
                                                    <div class="group_item">
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.thumbnail_embed }">
                                                            <Field autocomplete="off" type="textarea" name="thumbnail_embed" v-model="form.thumbnail.embed" rules="required" label="embed code">
                                                                <textarea name="thumbnail_embed" v-model="form.thumbnail.embed" placeholder="embeded code here.."></textarea>
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="thumbnail_embed" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Button Color</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="group_item">
                                        <label class="input_label">Selected Color</label>
                                        <Field autocomplete="off" name="btn_color" v-model="form.btn_color" type="text" label="element color">
                                            <color-picker v-model="form.btn_color" :classes="{ 'has-error': errors.btn_color }" />
                                        </Field>
                                        <ErrorMessage name="btn_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Button Text Color</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="group_item">
                                        <label class="input_label">Selected Color</label>
                                        <Field autocomplete="off" name="btn_txtcolor" v-model="form.btn_txtcolor" type="text" label="element color">
                                            <color-picker v-model="form.btn_txtcolor" :classes="{ 'has-error': errors.btn_txtcolor }" />
                                        </Field>
                                        <ErrorMessage name="btn_txtcolor" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="form.login_layout == 1">
                                <div class="section_header">
                                    <h2 class="sub_header m-0">
                                        Display Footer
                                    </h2>
                                    <label for="has-login-footer" class="switch_option capsule_btn p-0" @click="goTo('ftrsection')" ref="ftrsetting">
                                        <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.login_setting.display_footer" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab === 'chat'">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Enable Chat</h2>
                                    <label for="enable-chat" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="enable-chat" :true-value="1" :false-value="0" v-model="form.has_chatbox" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="extra_note" v-if="!form.has_chatbox">Please click on the toggle to turn on chat settings</div>
                                <template v-if="form.has_chatbox">
                                    <div class="section_header mt-5">
                                        <h2>Message Notifications</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item w-100">
                                            <div class="">
                                                <label for="enable-chat-email-notification" class="switch_option capsule_btn p-0">
                                                    <h5 class="large">Send New Message Notifications Via Email</h5>
                                                    <input type="checkbox" id="enable-chat-email-notification" :true-value="1" :false-value="0" v-model="form.portal_chat_email_notification" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Desktop Chat Placement</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item w-100">
                                            <div class="">
                                                <label for="button_left" class="switch_option capsule_btn p-0 border-0">
                                                    <h5 class="large">Left</h5>
                                                    <Field autocomplete="off" type="radio" name="button_left" id="button_left" value="left" v-model="form.chat_placement" hidden />
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="">
                                                <label for="button_right" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Right</h5>
                                                    <Field autocomplete="off" type="radio" name="button_right" id="button_right" value="right" v-model="form.chat_placement" hidden />
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-if="form.has_chatbox">
                                    <div class="section_header mt-5">
                                        <h2>Chat Background Color</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color</label>
                                            <Field autocomplete="off" name="chat_background_color" v-model="form.chat_background_color" type="text" label="element color">
                                                <color-picker v-model="form.chat_background_color" :classes="{ 'has-error': errors.chat_background_color }" />
                                            </Field>
                                            <ErrorMessage name="chat_background_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Coach Image</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <label for="coach_image" class="switch_option capsule_btn p-0 border-0">
                                            <h5 class="large">Coach Image</h5>
                                            <input type="checkbox" id="coach_image" :true-value="1" :false-value="0" v-model="form.chat_has_coach_image" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <label for="coach_new_image" class="switch_option capsule_btn p-0 mt-4 mb-3 border-0">
                                            <h5 class="large">Upload Personalized Image</h5>
                                            <input type="checkbox" id="coach_new_image"  :true-value="1" :false-value="0" v-model="form.chat_has_coach_custom_image" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="upload_image mb-3" v-if="form.chat_has_coach_custom_image">
                                            <image-library v-model="form.chat_coach_image" image-type="coach-logo" upload-text="New Image" />
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Coach Bubble Color</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color</label>
                                            <Field autocomplete="off" name="chat_coach_bubble_bg_color" v-model="form.chat_coach_bubble_bg_color" type="text" label="element color">
                                                <color-picker v-model="form.chat_coach_bubble_bg_color" :classes="{ 'has-error': errors.chat_coach_bubble_bg_color }" />
                                            </Field>
                                            <ErrorMessage name="chat_coach_bubble_bg_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Coach Bubble Text Color</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color</label>
                                            <Field autocomplete="off" name="chat_coach_bubble_text_color" v-model="form.chat_coach_bubble_text_color" type="text" label="element color">
                                                <color-picker v-model="form.chat_coach_bubble_text_color" :classes="{ 'has-error': errors.chat_coach_bubble_text_color }" />
                                            </Field>
                                            <ErrorMessage name="chat_coach_bubble_text_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Client Bubble Color</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color</label>
                                            <Field autocomplete="off" name="chat_client_bubble_bg_color" v-model="form.chat_client_bubble_bg_color" type="text" label="element color">
                                                <color-picker v-model="form.chat_client_bubble_bg_color" :classes="{ 'has-error': errors.chat_client_bubble_bg_color }" />
                                            </Field>
                                            <ErrorMessage name="chat_client_bubble_bg_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Client Bubble Text Color</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color</label>
                                            <Field autocomplete="off" name="chat_client_bubble_text_color" v-model="form.chat_client_bubble_text_color" type="text" label="element color">
                                                <color-picker v-model="form.chat_client_bubble_text_color" :classes="{ 'has-error': errors.chat_client_bubble_text_color }" />
                                            </Field>
                                            <ErrorMessage name="chat_client_bubble_text_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Chat Button Color</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color</label>
                                            <Field autocomplete="off" name="chat_button_bg_color" v-model="form.chat_button_bg_color" type="text" label="element color">
                                                <color-picker v-model="form.chat_button_bg_color" :classes="{ 'has-error': errors.chat_button_bg_color }" />
                                            </Field>
                                            <ErrorMessage name="chat_button_bg_color" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="section_header mt-5">
                                        <h2>Chat Button Icon Color</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color</label>
                                            <Field autocomplete="off" name="chat_button_text_color" v-model="form.chat_button_text_color" type="text" label="element color">
                                                <color-picker v-model="form.chat_button_text_color" :classes="{ 'has-error': errors.chat_button_text_color }" />
                                            </Field>
                                            <ErrorMessage name="chat_button_text_color" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab === 'footer'">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2 class="sub_header m-0">
                                        Footer
                                    </h2>
                                    <label for="has-login-footer" class="switch_option capsule_btn p-0" @click="goTo('ftrsection')" ref="ftrsetting">
                                        <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.display_footer" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div v-show="form.display_footer" class="section_content w-100">
                                <div class="section_header">
                                    <h2>Social Icons</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <label for="facebook" class="switch_option capsule_btn p-0 border-0">
                                        <h5 class="large">Facebook</h5>
                                        <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.has_facebook" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="setting_wpr" v-if="form.has_facebook">
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_facebook }">
                                                    <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                </div>
                                                <ErrorMessage name="footer_facebook" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="twitter" class="switch_option capsule_btn p-0 border-0 mt-4">
                                        <h5 class="large">Twitter</h5>
                                        <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.has_twitter" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="setting_wpr" v-if="form.has_twitter">
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_twitter }">
                                                    <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                </div>
                                                <ErrorMessage name="footer_twitter" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="instagram" class="switch_option capsule_btn p-0 border-0 mt-4">
                                        <h5 class="large">Instagram</h5>
                                        <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.has_instagram" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="setting_wpr" v-if="form.has_instagram">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_instagram }">
                                                    <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                </div>
                                                <ErrorMessage name="footer_instagram" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group_item mt-3">
                                        <label class="input_label">Icons Color</label>
                                        <Field autocomplete="off" name="footer_textcolor" v-model="form.footer_textcolor" type="text" label="element color">
                                            <color-picker v-model="form.footer_textcolor" :classes="{ 'has-error': errors.footer_textcolor }" />
                                        </Field>
                                        <ErrorMessage name="footer_textcolor" class="text-danger" />
                                    </div>
                                    <div class="group_item mt-3">
                                        <label class="input_label">Background Color</label>
                                        <Field autocomplete="off" name="footer_bgcolor" v-model="form.footer_bgcolor" type="text" label="element color">
                                            <color-picker v-model="form.footer_bgcolor" :classes="{ 'has-error': errors.footer_bgcolor }" />
                                        </Field>
                                        <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div v-show="form.display_footer" class="section_content w-100">
                                <div class="section_header">
                                    <h2>Footer Text</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Copyright Text</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_company }">
                                                    <Field autocomplete="off" type="text" name="footer_company" v-model="form.footer_company" :placeholder="`© ${new Date().getFullYear()} - Thrive Coach`" />
                                                </div>
                                                <ErrorMessage name="footer_company" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Terms Link</label>
                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_terms }">
                                                    <Field autocomplete="off" type="text" name="footer_terms" v-model="form.footer_terms" placeholder="ex: https://clientportal.com/terms" rules="url" label="term link" />
                                                    <span class="prefix">URL</span>
                                                </div>
                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                <ErrorMessage name="footer_terms" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Privacy Link</label>
                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_policy }">
                                                    <Field autocomplete="off" type="text" name="footer_policy" v-model="form.footer_policy" placeholder="ex: https://clientportal.com/privacy" rules="url" label="privacy link" />
                                                    <span class="prefix">URL</span>
                                                </div>
                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                <ErrorMessage name="footer_policy" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab === 'seo'">
                            <div class="section_content w-100" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                    <Field autocomplete="off" type="text" name="seo_title" v-model="form.seo_title" />
                                                </div>
                                                <ErrorMessage name="seo_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                    <Field autocomplete="off" type="text" name="seo_keyword" v-model="form.seo_keyword" />
                                                </div>
                                                <ErrorMessage name="seo_keyword" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_desc }">
                                                    <Field autocomplete="off" type="text" name="seo_desc" v-model="form.seo_desc" />
                                                </div>
                                                <ErrorMessage name="seo_desc" class="text-danger" />
                                            </div>
                                        </div>
                                        <label for="seo-index" class="switch_option capsule_btn border-0">
                                            <h5 class="large">I want my page indexed by search engine</h5>
                                            <input type="checkbox" id="seo-index" :true-value="1" :false-value="0" v-model="form.seo_index" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Tracking</h2>
                                </div>
                                <div class="color_container">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Facebook Pixel Code</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.fb_pixel }">
                                                    <Field autocomplete="off" type="textarea" name="fb_pixel" v-model="form.fb_pixel" label="description">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.fb_pixel"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="fb_pixel" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                    <Field autocomplete="off" type="text" name="seo_share_title" v-model="form.seo_share_title" placeholder="Thrive Coach" />
                                                </div>
                                                <ErrorMessage name="seo_share_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_desc }">
                                                    <Field autocomplete="off" type="text" name="seo_share_desc" v-model="form.seo_share_desc" placeholder="We Are Awesome! Harnessing the Power of Technology to Advance Humanity" />
                                                </div>
                                                <ErrorMessage name="seo_share_desc" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">SEO Thumbnail</label>
                                                <image-library v-model="form.seo_thumb" image-type="social-share" :crop="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <image-library v-model="form.seo_fav" image-type="favicon" :crop="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab === 'delivery'">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Delivery Message</h2>
                                </div>
                                <div class="color_container mt-2 pt-2 delivery-message">
                                    <ul class="tab_sec justify-content-start mt-3">
                                        <li @click="deliveryTab = 1" :class="{ active: deliveryTab === 1 }">Email</li>
                                        <li @click="deliveryTab = 2" :class="{ active: deliveryTab === 2 }">SMS</li>
                                    </ul>
                                    <div v-if="deliveryTab == 1">
                                        <email-component v-model="deliveryEmail" :errors="errors" :handle-default-email="handleDefaultEmail" :has-email-button="true" preview-module="client-portal" ref="email-component" is-full-view />
                                    </div>
                                    <div v-show="deliveryTab == 2">
                                        <sms-component v-model="form.delivery_sms" media-field-name="delivery_sms_media" :media="form.delivery_sms_media" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" :update-sms-text="updateSmsText()" ref="client-portal-sms-component" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true" v-if="tab !== 'drip'">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="updateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="updateLoader"></i> {{ updateLoader ? 'Updating' : 'Update' }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'global' || tab === 'portal' || tab === 'footer'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_portal_v2" class="switch_option capsule_btn p-0 border-0" @click="handlePreview('fullPortal')">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_portal_v2" v-model="fullPortal" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <!-- <div class="small-cover-info" v-if="!form.small_cover">
                    <small>Upload a small screen cover for a better view</small>
                </div> -->
                <div class="cell">
                    <div class="content_area">
                        <div class="dashboard">
                            <div class="client_card border-0">
                                <div class="card_header pointer" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`" @click="setting('hdrsetting')" ref="hdrsection">
                                    <nav>
                                        <img class="logo_img" :src="form.logo ? form.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                        <a>
                                            <h4>Dashboard</h4>
                                        </a>
                                    </nav>
                                    <div class="user_box" @click="accountDrop = !accountDrop">
                                        <img :src="form.author ? form.author : user.profile_pic" :alt="user.full_name">
                                        <div class="user_info">
                                            <h5 :style="`color: ${form.header_textcolor};`">{{ user.full_name }} <i class="fas fa-caret-down" :class="`${accountDrop ? 'swing' : ''}`"></i></h5>
                                        </div>
                                        <ul class="account_drop text-inherit" :class="{ active : accountDrop }">
                                            <li><i class="fas fa-home"></i> Primary Location</li>
                                            <li><i class="far fa-user"></i> Profile Info</li>
                                            <li><i class="far fa-file"></i> My Files</li>
                                            <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="banner" v-if="form.display_cover && form.has_small_screen_cover" ref="bnrsection" @click="setting('bnrsetting')" :class="{'cover-image-sm' : form.cover_type == 1}">
                                <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                    <source v-if="form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                    <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                </picture>
                                <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                                <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                <div class="bnr_content">
                                    <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size / 3 < 20 ? 20 : form.headline_setting.font_size / 3}px; line-height: ${form.headline_setting.font_size / 3 < 20 ? 26 :(parseInt(form.headline_setting.font_size) + 20) / 3}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                                    <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size / 3 < 11 ? 11 : form.subheadline_setting.font_size / 3}px; line-height: ${form.subheadline_setting.font_size / 3 < 11 ? 16 :(parseInt(form.subheadline_setting.font_size) + 20) / 3}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                                </div>
                            </div>
                            <div class="dash_tabs mb-5" :class="{'mt-2' : !form.display_cover || !form.has_small_screen_cover}" ref="navsection" @click="setting('navsetting')">
                                <ul class="tab_btn">
                                    <li :style="`background: ${form.portal_tab_bg_color}; color: ${form.portal_tab_text_color}`"><i class="fas fa-home"></i></li>
                                    <li v-if="form.playbook_tab || form.page_tab">Classroom</li>
                                    <li v-if="form.calendar_tab">Calendar</li>
                                    <li v-if="form.form_tab">Forms</li>
                                    <li v-if="form.progress_tab">Charts</li>
                                </ul>
                            </div>
                            <div class="px-3 mb-5">
                                <h4 class="pb-3" v-if="form.activity_widget && (form.has_habit_score || form.has_progress_score || form.has_portal_login || form.has_referrals)" ref="activity" :style="`color: ${form.announcement_title_text_color}`">Your Activity</h4>
                                <ul class="activity_score" v-if="form.activity_widget && (form.has_habit_score || form.has_progress_score || form.has_portal_login || form.has_referrals)">
                                    <li @click="setting('activity1')" v-show="form.has_habit_score">
                                        <div class="score_circle">
                                            <div class="inner_circle">
                                                <div class="score">92<span>%</span></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="form.habit_score_color" :stroke-dasharray="130"/>
                                            </svg>
                                        </div>
                                        <h5>Habit Score</h5>
                                    </li>
                                    <li @click="setting('activity2')" v-show="form.has_progress_score">
                                        <div class="score_circle">
                                            <div class="inner_circle">
                                                <div class="score">64<span>%</span></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="form.progress_score_color" :stroke-dasharray="92"/>
                                            </svg>
                                        </div>
                                        <h5>Progress Score</h5>
                                    </li>
                                    <li @click="setting('activity3')" v-show="form.has_portal_login">
                                        <div class="score_circle">
                                            <div class="inner_circle">
                                                <div class="score">76</div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="form.login_score_color" :stroke-dasharray="100"/>
                                            </svg>
                                        </div>
                                        <h5>Portal Logins</h5>
                                    </li>
                                    <li @click="setting('activity4')" v-show="form.has_referrals">
                                        <div class="score_circle">
                                            <div class="inner_circle">
                                                <div class="score">105</div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="form.referral_score_color" :stroke-dasharray="75"/>
                                            </svg>
                                        </div>
                                        <h5>Referrals</h5>
                                    </li>
                                </ul>
                                <div class="feature_area pt-2" ref="announcement" @click="setting('announsetting')">
                                    <div class="right_side" v-if="form.has_welcome_video && form.has_announcement">
                                        <h4 class="mt-5 mb-3" :style="`color: ${form.announcement_title_text_color};`">{{ form.announcement_title }}</h4>
                                        <div class="extra_info" :style="`background: ${form.announcement_bg_color}; padding: ${form.has_announcement_link == 0 ? '18px 10px' : ''};`">
                                            <span :style="`color: ${form.announcement_text_color}`">{{ form.announcement_message }}</span>
                                            <div class="action_wpr mt-1" v-show="form.has_announcement_link">
                                                <h6>
                                                    <a :href="form.announcement_link" target="_blank" :style="`color: ${form.announcement_link_color}`">{{ form.announcement_link_text }}</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="feature_area pt-2" ref="welcomevideo" @click="setting('videosetting')">
                                    <div class="left_side" v-if="form.has_welcome_video">
                                        <h4 class="mt-5 mb-3" :style="`color: ${form.announcement_title_text_color}`">{{ form.welcome_video_title ? form.welcome_video_title : 'Welcome' }}</h4>
                                        <img class="pb-2" src="@/assets/images/thumb/video-placeholder.svg" alt="" v-if="form.welcome_video_type == 1 && !form.welcome_video_link || form.welcome_video_type == 2 && !form.welcome_video_embed_code">
                                        <div class="video_wpr pb-2" v-else>
                                            <div v-if="form.welcome_video_type == 1" v-html="parseEmbedCode(form.welcome_video_link)"></div>
                                            <div v-else v-html="form.welcome_video_embed_code"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="full_width" v-if="!form.has_welcome_video && form.has_announcement" ref="announcement" @click="setting('videosetting')">
                                    <h4 class="mt-4 mb-2" :style="`color: ${form.announcement_title_text_color};`">{{ form.announcement_title }}</h4>
                                    <div class="extra_info" :style="`background: ${form.announcement_bg_color};`">
                                        <span :style="`color: ${form.announcement_text_color}`">{{ form.announcement_message }}</span>
                                        <div class="action_wpr mt-1" v-if="form.has_announcement_link">
                                            <h6>
                                                <a :href="form.announcement_link" target="_blank" :style="`color: ${form.announcement_link_color}`">{{ form.announcement_link_text }}</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div ref="pintraining" @click="setting('pinsetting')">
                                    <h4 class="mt-4 mb-0" v-if="form.pinned_widget" :style="`color: ${form.announcement_title_text_color}`">Pinned Training</h4>
                                    <div class="sliderWpr" v-if="form.pinned_widget">
                                        <ul class="client_productList">
                                            <li v-for="(playbook, p) of pinnedPlaybooks" :key="p">
                                                <div class="product_card">
                                                    <img v-if="playbook.dashboard_thumb" :src="playbook.dashboard_thumb" alt="" class="product_thumb">
                                                    <img v-else src="@/assets/images/thumb/default-playbook.svg" alt="" class="product_thumb">
                                                    <div class="product_cont playbook-desc">
                                                        <h4>{{ playbook.title.length > 35 ? playbook.title.substr(0, 35)+'...' : playbook.title }}</h4>
                                                        <p>{{ playbook.description && playbook.description.length > 70 ? playbook.description.substr(0, 70)+'...' : playbook.description }}</p>
                                                        <a href="javascript:void(0);" class="primary_btn" :style="`background-color: ${form.portal_tab_bg_color != null ? form.portal_tab_bg_color : '#2f7fed'}; border-color: ${form.portal_tab_bg_color != null ? form.portal_tab_bg_color : '#2f7fed'}; color: ${form.portal_tab_text_color != null ? form.portal_tab_text_color : '#FFF'};`">Open Playbook</a>
                                                    </div>
                                                    <button type="button" class="pin custom-pin"><i class="fas fa-thumbtack"></i></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref="ftrsection" @click="setting('ftrsetting')" class="mt-5">
                            <footer class="dashboard_footer" v-if="form.display_footer" :style="`background: ${form.footer_bgcolor};`">
                                <ul>
                                    <li class="pointer" v-if="form.has_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                    <li class="pointer" v-if="form.has_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                    <li class="pointer" v-if="form.has_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                </ul>
                                <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company ? form.footer_company : `${new Date().getFullYear()} - Thrive Coach` }}</h4>
                                <p>
                                    <a :href="form.footer_terms" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                    <a :href="form.footer_policy" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                    <span v-html="clientPortal.white_label"></span>
                                </p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'login'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_login_v2" class="switch_option capsule_btn p-0" @click="handlePreview('fullLogin')">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_login_v2" v-model="fullLogin" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="banner_area" v-if="form.login_layout == 1">
                            <div class="banner_wpr banner_cover" :class="{'cover-image-sm' : form.login_setting.cover_type == 1}" :style="`background-color: ${form.login_setting.cover ? 'transparent' : '#f5f5f5'}`" v-if="form.login_setting && form.login_setting.has_cover && form.login_setting.display_small_cover">
                                <picture v-if="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3)">
                                    <source v-if="form.login_setting && form.login_setting.small_cover != null && form.login_setting.small_cover != '' && form.login_setting.small_cover != 'null'" :srcset="`${form.login_setting.small_cover}`" media="(max-width: 768px)">
                                    <img :src="form.login_setting && form.login_setting.small_cover ? form.login_setting.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                </picture>
                                <span v-if="form.login_setting.cover_type == 1" class="overlay" :style="`background-color: ${form.login_setting.cover_background_color ? form.login_setting.cover_background_color : form.login_setting.opac_overlay_color};`"></span>
                                <span v-else-if="form.login_setting.cover_type == 2" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                <span v-else-if="form.login_setting.cover_type == 3" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                <div class="bnr_content" :class="{'py-3' : form.login_setting.cover_type == 1}" :style="`position: ${form.login_setting.cover_type == 1 ? 'relative' : 'absolute'}`">
                                    <h4 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 20 : form.login_setting.headline_setting_font_size / 3}px; line-height: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 28 : (parseInt(form.login_setting.headline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.headline_setting_font_weight}; font-family: ${form.login_setting.headline_setting_font_family}; color: ${form.login_setting.headline_setting_font_color}; ${form.login_setting.headline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.headline_setting_shadow_x +'px ' + form.login_setting.headline_setting_shadow_y + 'px ' + form.login_setting.headline_setting_shadow_blur + 'px ' + form.login_setting.headline_setting_shadow_color : 'none'}`">{{ form.login_setting.headline }}</h4>
                                    <h1 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 11 : form.login_setting.subheadline_setting_font_size / 3}px; line-height: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 18 : (parseInt(form.login_setting.subheadline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.subheadline_setting_font_weight}; font-family: ${form.login_setting.subheadline_setting_font_family}; color: ${form.login_setting.subheadline_setting_font_color}; ${form.login_setting.subheadline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.subheadline_setting_shadow_x +'px ' + form.login_setting.subheadline_setting_shadow_y + 'px ' + form.login_setting.subheadline_setting_shadow_blur + 'px ' + form.login_setting.subheadline_setting_shadow_color : 'none'}`">{{ form.login_setting.subheadline }}</h1>
                                </div>
                            </div>
                            <div class="login" :class="{'mt-50': (form.login_setting.has_cover == 0 || !form.login_setting.display_small_cover)}">
                                <div class="login-thumbnail" v-if="form.display_login_card">
                                    <div v-if="form.thumbnail_type == 1" class="img_wpr">
                                        <img :src="form.login_card ? form.login_card : require('@/assets/images/thumb/default-sm-cover.svg')" />
                                    </div>
                                    <div class="video_wpr" v-if="form.thumbnail_type == 3 && form.thumbnail.url" v-html="parseEmbedCode(form.thumbnail.url)"></div>
                                    <div class="video_wpr" v-if="form.thumbnail_type == 2 && form.thumbnail.embed" v-html="form.thumbnail.embed"></div>
                                    <img src="@/assets/images/thumb/video-placeholder.svg" alt="" v-if="(form.thumbnail_type == 3 && !form.thumbnail.url) || (form.thumbnail_type == 2 && !form.thumbnail.embed)"/>
                                </div>
                                <div class="setting_wpr">
                                    <h2>{{ clientPortal.login_title }}</h2>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="Robert Bacins" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Password</label>
                                            <div class="field_wpr">
                                                <input type="password" placeholder="*****" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group-col-2">
                                        <label for="remember" class="checkbox">
                                        <input type="checkbox" id="remember" hidden />
                                        <span class="mr-1"><i class="fas fa-check"></i></span> Remember Me
                                        </label>
                                        <div class="forgot">
                                            <a class="pointer">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <button type="button" class="primary_btn mt-4" :style="`background: ${form.btn_color ? form.btn_color : '#2F7FED'}; border-color: ${form.btn_color ? form.btn_color : '#2F7FED'}; color: ${form.btn_txtcolor ? form.btn_txtcolor : '#FFF'};`">{{ form.btn_text }}</button>
                                </div>
                            </div>
                            <div ref="ftrsection" @click="setting('ftrsetting')" class="mt-auto">
                                <footer class="dashboard_footer" v-if="form.login_setting.display_footer" :style="`background: ${form.footer_bgcolor};`">
                                    <ul >
                                        <li class="pointer" v-if="form.has_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                        <li class="pointer" v-if="form.has_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                        <li class="pointer" v-if="form.has_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                    </ul>
                                    <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company ? form.footer_company : `${new Date().getFullYear()} - Thrive Coach` }}</h4>
                                    <p>
                                        <a :href="form.footer_terms" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                        <a :href="form.footer_policy" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                        <span v-html="clientPortal.white_label"></span>
                                    </p>
                                </footer>
                            </div>
                        </div>
                        <div class="banner_area2" v-else-if="form.login_layout == 2">
                            <div class="banner_wpr" :style="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3) && form.login_setting.has_cover ? `background-image: url(${form.login_setting.cover ? form.login_setting.cover : require('@/assets/images/thumb/default-cover-2-3.svg')})` : ''">
                                <!-- <img :src="form.login_setting.cover" alt="banner" class="banner_img" v-if="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3) && form.login_setting.cover"> -->
                                <div class="bnrCont_wpr banner_cover2" :class="{'cover-image-sm' : form.login_setting.cover_type !== 3}" v-if="form.login_setting.has_cover">
                                    <span v-if="form.login_setting.cover_type == 1" class="overlay" :style="`background-color: ${form.login_setting.cover_background_color ? form.login_setting.cover_background_color : form.login_setting.opac_overlay_color};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 2" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 3" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <h4 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 20 : form.login_setting.headline_setting_font_size / 3}px; line-height: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 28 : (parseInt(form.login_setting.headline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.headline_setting_font_weight}; font-family: ${form.login_setting.headline_setting_font_family}; color: ${form.login_setting.headline_setting_font_color}; ${form.login_setting.headline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.headline_setting_shadow_x +'px ' + form.login_setting.headline_setting_shadow_y + 'px ' + form.login_setting.headline_setting_shadow_blur + 'px ' + form.login_setting.headline_setting_shadow_color : 'none'}`">{{ form.login_setting.headline }}</h4>
                                    <h1 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 11 :form.login_setting.subheadline_setting_font_size / 3}px; line-height: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 18 : (parseInt(form.login_setting.subheadline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.subheadline_setting_font_weight}; font-family: ${form.login_setting.subheadline_setting_font_family}; color: ${form.login_setting.subheadline_setting_font_color}; ${form.login_setting.subheadline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.subheadline_setting_shadow_x +'px ' + form.login_setting.subheadline_setting_shadow_y + 'px ' + form.login_setting.subheadline_setting_shadow_blur + 'px ' + form.login_setting.subheadline_setting_shadow_color : 'none'}`">{{ form.login_setting.subheadline }}</h1>
                                </div>
                                <div class="login" :class="{ login_cover_bg: (form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3) && form.login_setting.cover }">
                                    <div class="login-thumbnail" v-if="form.display_login_card">
                                        <div v-if="form.thumbnail_type == 1" class="img_wpr">
                                            <img :src="form.login_card ? form.login_card : require('@/assets/images/thumb/default-sm-cover.svg')" />
                                        </div>
                                        <div class="video_wpr" v-if="form.has_login_video && form.thumbnail_type == 3 && form.thumbnail.url" v-html="parseEmbedCode(form.thumbnail.url)"></div>
                                        <div class="video_wpr" v-if="form.thumbnail_type == 2 && form.thumbnail.embed" v-html="form.thumbnail.embed"></div>
                                        <img src="@/assets/images/thumb/video-placeholder.svg" alt="" v-if="(form.thumbnail_type == 3 && !form.thumbnail.url) || (form.thumbnail_type == 2 && !form.thumbnail.embed)"/>
                                    </div>
                                    <div class="setting_wpr">
                                        <h2>{{ clientPortal.login_title }}</h2>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Email</label>
                                                <div class="field_wpr">
                                                    <input type="text" placeholder="Robert Bacins" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Password</label>
                                                <div class="field_wpr">
                                                    <input type="password" placeholder="*****" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group-col-2">
                                            <label for="remember" class="checkbox">
                                            <input type="checkbox" id="remember" hidden />
                                            <span class="mr-1"><i class="fas fa-check"></i></span> Remember Me
                                            </label>
                                            <div class="forgot">
                                                <router-link to="/">Forgot Password?</router-link>
                                            </div>
                                        </div>
                                        <button type="button" class="primary_btn mt-4" :style="`background: ${form.btn_color ? form.btn_color : '#2F7FED'}; border-color: ${form.btn_color ? form.btn_color : '#2F7FED'}; color: ${form.btn_txtcolor ? form.btn_txtcolor : '#FFF'};`">{{ form.btn_text }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="banner_area3" v-else-if="form.login_layout == 3">
                            <div class="banner_wpr banner_cover" :style="`background-color: ${form.login_setting.cover ? 'transparent' : '#f5f5f5'}; height: ${form.login_setting.cover_type == 1 ? '100%' : ''}`">
                                <template v-if="form.login_setting && form.login_setting.has_cover && form.login_setting.display_small_cover">
                                    <picture v-if="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3)">
                                        <source v-if="form.login_setting && form.login_setting.small_cover != null && form.login_setting.small_cover != '' && form.login_setting.small_cover != 'null'" :srcset="`${form.login_setting.small_cover}`" media="(max-width: 768px)">
                                        <img :src="form.login_setting && form.login_setting.small_cover ? form.login_setting.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                    </picture>
                                    <span v-if="form.login_setting.cover_type == 1" class="overlay" :style="`background-color: ${form.login_setting.cover_background_color ? form.login_setting.cover_background_color : form.login_setting.opac_overlay_color};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 2" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 3" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <div class="bnrCont_wpr banner_cover3">
                                        <h4 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 20 : form.login_setting.headline_setting_font_size / 3}px; line-height: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 28 : (parseInt(form.login_setting.headline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.headline_setting_font_weight}; font-family: ${form.login_setting.headline_setting_font_family}; color: ${form.login_setting.headline_setting_font_color}; ${form.login_setting.headline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.headline_setting_shadow_x +'px ' + form.login_setting.headline_setting_shadow_y + 'px ' + form.login_setting.headline_setting_shadow_blur + 'px ' + form.login_setting.headline_setting_shadow_color : 'none'}`">{{ form.login_setting.headline }}</h4>
                                        <h1 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 11 : form.login_setting.subheadline_setting_font_size / 3}px; line-height: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 18 : (parseInt(form.login_setting.subheadline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.subheadline_setting_font_weight}; font-family: ${form.login_setting.subheadline_setting_font_family}; color: ${form.login_setting.subheadline_setting_font_color}; ${form.login_setting.subheadline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.subheadline_setting_shadow_x +'px ' + form.login_setting.subheadline_setting_shadow_y + 'px ' + form.login_setting.subheadline_setting_shadow_blur + 'px ' + form.login_setting.subheadline_setting_shadow_color : 'none'}`">{{ form.login_setting.subheadline }}</h1>
                                    </div>
                                </template>
                            </div>
                            <div class="login" :class="{'mt-auto' : form.login_setting.cover_type == 1}">
                                <div class="login-thumbnail" v-if="form.display_login_card">
                                    <div v-if="form.thumbnail_type == 1" class="img_wpr">
                                        <img :src="form.login_card ? form.login_card : require('@/assets/images/thumb/default-sm-cover.svg')" />
                                    </div>
                                    <div class="video_wpr" v-if="form.thumbnail_type == 3 && form.thumbnail.url" v-html="parseEmbedCode(form.thumbnail.url)"></div>
                                    <div class="video_wpr" v-if="form.thumbnail_type == 2 && form.thumbnail.embed"  v-html="form.thumbnail.embed"></div>
                                    <img src="@/assets/images/thumb/video-placeholder.svg" alt="" v-if="(form.thumbnail_type == 3 && !form.thumbnail.url) || (form.thumbnail_type == 2 && !form.thumbnail.embed)"/>
                                </div>
                                <div class="setting_wpr">
                                    <h2>{{ clientPortal.login_title }}</h2>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="Robert Bacins" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Password</label>
                                            <div class="field_wpr">
                                                <input type="password" placeholder="*****" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group-col-2">
                                        <label for="remember" class="checkbox">
                                            <input type="checkbox" id="remember" hidden />
                                            <span class="mr-1"><i class="fas fa-check"></i></span> Remember Me
                                        </label>
                                        <div class="forgot">
                                            <a href="javascript:void(0);">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <button type="button" class="primary_btn mt-4" :style="`background: ${form.btn_color ? form.btn_color : '#2F7FED'}; border-color: ${form.btn_color ? form.btn_color : '#2F7FED'}; color: ${form.btn_txtcolor ? form.btn_txtcolor : '#FFF'};`">{{ form.btn_text }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'chat' && form.has_chatbox">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="chat" :style="`background: ${form.chat_background_color};`" :class="{'show' : chat}" v-if="form.has_chatbox">
                            <button class="chat_close" @click="chat = false" :style="`background: ${form.chat_button_bg_color}; color: ${form.chat_button_text_color};`"><i class="fas fa-angle-down"></i></button>
                            <div class="chat_user" >
                                <span class="option">
                                    <i class="fas fa-ellipsis-v" :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`"></i>
                                    <ul>
                                        <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                        <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                        <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                    </ul>
                                </span>
                                <div class="msg">
                                    <p :style="`background: ${form.chat_coach_bubble_bg_color}; color: ${form.chat_coach_bubble_text_color};`">How can I help you today?</p>
                                    <span :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`">9h ago</span>
                                </div>
                                <img v-if="form.chat_has_coach_image" :src="form.chat_coach_image ? form.chat_coach_image : user.profile_pic" alt="">
                                <img v-else src="@/assets/images/male-female.png" alt="">
                            </div>
                            <div class="chat_client">
                                <img src="@/assets/images/male-female.png" alt="">
                                <div class="msg">
                                    <p :style="`background: ${form.chat_client_bubble_bg_color}; color: ${form.chat_client_bubble_text_color};`">Hey Michele, nice to meet you!</p>
                                    <span :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`">9h ago</span>
                                </div>
                                <span class="option">
                                    <i class="fas fa-ellipsis-v" :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`"></i>
                                    <ul>
                                        <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                        <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                        <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                    </ul>
                                </span>
                            </div>
                            <div class="chat_client">
                                <img src="@/assets/images/male-female.png" alt="">
                                <div class="msg">
                                    <p :style="`background: ${form.chat_client_bubble_bg_color}; color: ${form.chat_client_bubble_text_color};`">Have a great day!</p>
                                    <span :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`">4m ago</span>
                                </div>
                                <span class="option">
                                    <i class="fas fa-ellipsis-v" :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`"></i>
                                    <ul>
                                        <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                        <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                        <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                    </ul>
                                </span>
                            </div>
                            <div class="chat_user">
                                <span class="option">
                                    <i class="fas fa-ellipsis-v" :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`"></i>
                                    <ul>
                                        <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                        <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                        <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                    </ul>
                                </span>
                                <div class="msg">
                                    <p :style="`background: ${form.chat_coach_bubble_bg_color}; color: ${form.chat_coach_bubble_text_color};`">Thanks buddy, you too as well.</p>
                                    <span :style="`color: ${form.chat_background_color !== '#FFFFFF' ? '#fff' : ''};`">3m ago</span>
                                </div>
                                <img v-if="form.chat_has_coach_image" :src="form.chat_coach_image ? form.chat_coach_image : user.profile_pic" alt="">
                                <img v-else src="@/assets/images/male-female.png" alt="">
                            </div>
                        </div>
                        <button v-if="form.has_chatbox" type="button" class="chat_btn pointer" @click="chat = !chat" :style="`background: ${form.chat_button_bg_color}; color: ${form.chat_button_text_color}; float: ${form.chat_placement};`">
                            <i class="fas fa-comments mr-1"></i> Chat
                        </button>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'seo'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{form.seo_title}}</h4>
                        <p>{{form.seo_desc}}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="validLink(form.seo_thumb) ? form.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.seo_share_title ? form.seo_share_title : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.seo_share_desc ? form.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.seo_fav ? form.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.seo_title ? (form.seo_title.length > 15 ? form.seo_title.substr(0, 15)+'...' : form.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'delivery'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_msg" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_msg" v-model="previewMsg" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="deliveryTab = 1" :class="{ active: deliveryTab === 1 }">E-mail</li>
                    <li @click="deliveryTab = 2" :class="{ active: deliveryTab === 2 }">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="deliveryTab === 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(deliveryEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="deliveryEmail.show_email_logo">
                                <img :src="deliveryEmail.email_logo ? deliveryEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(deliveryEmail.email, deliveryEmail.email_button_bg_color, deliveryEmail.email_button_text_color)"></div>
                            <div v-html="generateSignature(deliveryEmail.is_signature, deliveryEmail.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mb-3">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="deliveryTab === 2">
                        <div class="sms_preview">
                            <pre>
                                <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(clientPortalSmsText)"></div>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
            <portal-preview  v-model="fullPortal" />
            <login-preview v-model="fullLogin" />
            <message-preview v-model="previewMsg"/>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const LoginPreview = defineAsyncComponent(() => import('@/pages/client-portal/components/LoginPreview'))
    const PortalPreview = defineAsyncComponent(() => import('@/pages/client-portal/components/PortalPreview'))
    const MessagePreview = defineAsyncComponent(() => import('@/pages/client-portal/components/MessagePreview'))

    import Toastr from '@/utils/Toastr'
    import VideoParser from '@/utils/VideoParser'

    export default {
        name: 'Client Portal Setting',

        data () {
            return {
                tab: 'portal',
                deliveryTab: 1,
                fullPortal: false,
                fullLogin: false,
                preview: true,
                previewLoader: false,
                habitScore: true,
                progresScore: true,
                portaLogins: true,
                referrals: true,
                whiteLabel: {},
                previewMsg: false,
                accountDrop: false,
                form: {
                    portal_url: '',
                    portal_tab_bg_color: '#2F80ED',
                    portal_tab_text_color: '#FFF',
                    has_announcement: 0,
                    announcement_bg_color: '#95d7ef66',
                    announcement_message: '',
                    has_announcement_link: 0,
                    announcement_title: '',
                    announcement_link_text: '',
                    announcement_link: '',
                    has_welcome_video: 0,
                    welcome_video_type: 1,
                    welcome_video_title: '',
                    welcome_video_link: '',
                    welcome_video_embed_code: '',
                    playbook_tab: 1,
                    page_tab: 1,
                    form_tab: 1,
                    calendar_tab: 1,
                    progress_tab: 1,
                    workout_tab: 1,
                    habit_score_color: '#2F7FED',
                    progress_score_color: '#F2A31D',
                    login_score_color: '#95D7EF',
                    referral_score_color: '#999999',
                    btn_color: '#2F7FED',
                    btn_txtcolor: '#FFF',
                    login_layout: 1,
                    login_title: '',
                    btn_text: '',
                    display_login_card: 0,
                    thumbnail_type: '',
                    login_card: '',
                    has_login_video: 0,
                    thumbnail: {},
                    chat_client_bubble_bg_color: '#FFF',
                    chat_client_bubble_text_color: '#000',
                    chat_coach_bubble_bg_color: '#2c3e50',
                    chat_coach_bubble_text_color: '#FFF',
                    chat_has_coach_image: 0,
                    chat_has_coach_custom_image: 0,
                    chat_button_bg_color: '#2c3e50',
                    chat_button_text_color: '#FFF',
                    chat_coach_image: '',
                    has_facebook: 0,
                    has_twitter: 0,
                    has_instagram: 0,
                    footer_facebook: '',
                    footer_twitter: '',
                    footer_instagram: '',
                    footer_company: '',
                    footer_terms: '',
                    footer_policy: '',
                    footer_textcolor: '#2F7EED',
                    footer_bgcolor: '#f5f5f5',
                    delivery_sms: '',
                    seo_fav: '',
                    seo_share_title: '',
                    seo_share_desc: '',
                    seo_thumb: '',
                    seo_title: 'Thrive Coach - The marketing automation platform for coaches',
                    seo_keyword: '',
                    seo_desc: 'Wherever you go, Thrive Coach is there acting as your personal assistant. Thrive Coach notifies you of everything going on in your business from new leads, form',
                    seo_index: '',
                    fb_pixel: '',
                    chat_background_color: '#f2f5fc',
                    has_chatbox: 1,
                    portal_chat_email_notification: 1,
                    has_footer: 1,
                    chat_placement: 'right',
                    display_footer: 1,
                    pinned_widget: 1,
                    login_setting: 0,
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: '#2F7FED',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: '#2F7FED',
                    },
                    cover: '',
                    has_small_screen_cover: 0,
                    small_cover: '',
                    has_cover_overlay: 0,
                    overlay_opacity: 20,
                    cover_type: 1,
                    headline: 'WELCOME TO',
                    description: 'YOUR CLIENT PORTAL',
                    overlay_color: '#2c3e50',
                    opac_overlay_color: '20',
                    cover_background_color: '#2F7FED',
                    display_cover: 1,
                    activity_widget: 1,
                    delivery_sms_media: [],
                    company_branding: '',
                    header_bgcolor: '',
                    header_textcolor: '',
                    logo: '',
                    author: '',
                },
                deliveryEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                loginHeadlineSetting: false,
                loginSubheadlineSetting: false,
                headlineSetting: false,
                subheadlineSetting: false,
                chat: true,
                env: {},
                delivery_sms_media: [],
                clientPortalSmsText: '',
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            PortalPreview,
            LoginPreview,
            MessagePreview
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            delivery_sms_media (media) {
                const vm = this;

                vm.form.delivery_sms_media = media;
            },
        },

        computed: {
            ...mapState({
                clientPortal: state => state.clientPortalModule.clientPortal,
                updateLoader: state => state.clientPortalModule.updateLoader,
                pinnedPlaybooks: state => state.clientPortalModule.pinnedPlaybooks,
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted() {
            const vm = this;
            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                updatePortalSetting: 'clientPortalModule/updatePortalSetting',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            switchTab (tab) {
                const vm = this;

                vm.tab = tab;
            },

            resetForm () {
                const vm = this;
                let headline_setting = vm.clientPortal.headline_setting ? JSON.parse(vm.clientPortal.headline_setting) : {};
                let subheadline_setting = vm.clientPortal.subheadline_setting ? JSON.parse(vm.clientPortal.subheadline_setting) : {};
                let login_setting = (vm.clientPortal.login_setting && typeof JSON.parse(vm.clientPortal.login_setting) == 'object') ? JSON.parse(vm.clientPortal.login_setting) : {};
                login_setting.has_cover = login_setting.has_cover ? login_setting.has_cover : 0;

                if (login_setting.display_footer == undefined) {
                    login_setting.display_footer = 1;
                }

                if (headline_setting.font_size == undefined) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: '#2F7FED',
                    };
                }

                if (subheadline_setting.font_size == undefined) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: '#2F7FED',
                    };
                }

                if (login_setting.headline_setting_font_size == undefined) {
                    login_setting = {
                        has_cover: 1,
                        cover_type: 2,
                        headline: 'WELCOME TO',
                        subheadline: 'YOUR CLIENT PORTAL',
                        headline_setting_font_family: 'Inter',
                        headline_setting_font_weight: 500,
                        headline_setting_font_size: 50,
                        headline_setting_font_color: '#2C3E50',
                        headline_setting_has_shadow: 0,
                        headline_setting_shadow_x: 0,
                        headline_setting_shadow_y: 4,
                        headline_setting_shadow_blur: 4,
                        headline_setting_shadow_color: '#2F7FED',
                        subheadline_setting_font_family: 'Inter',
                        subheadline_setting_font_weight: 500,
                        subheadline_setting_font_size: 25,
                        subheadline_setting_font_color: '#2C3E50',
                        subheadline_setting_has_shadow: 0,
                        subheadline_setting_shadow_x: 0,
                        subheadline_setting_shadow_y: 4,
                        subheadline_setting_shadow_blur: 4,
                        subheadline_setting_shadow_color: '#2F7FED',
                    };
                }

                login_setting.display_small_cover = login_setting.small_cover ? 1 : 0;

                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    login_setting,
                    portal_url: vm.clientPortal.portal_url ? vm.clientPortal.portal_url : '',
                    portal_tab_bg_color: vm.clientPortal.portal_tab_bg_color ? vm.clientPortal.portal_tab_bg_color : '#2F80ED',
                    portal_tab_text_color: vm.clientPortal.portal_tab_text_color ? vm.clientPortal.portal_tab_text_color : '#FFF',
                    has_announcement: vm.clientPortal.has_announcement ? vm.clientPortal.has_announcement : 0,
                    announcement_title: vm.clientPortal.announcement_title ? vm.clientPortal.announcement_title : 'New Announcement',
                    announcement_bg_color: vm.clientPortal.announcement_bg_color ? vm.clientPortal.announcement_bg_color : '#95d7ef66',
                    announcement_message: vm.clientPortal.announcement_message ? vm.clientPortal.announcement_message : '',
                    has_announcement_link: vm.clientPortal.has_announcement_link ? vm.clientPortal.has_announcement_link : 0,
                    announcement_link_text: vm.clientPortal.announcement_link_text ? vm.clientPortal.announcement_link_text : '',
                    announcement_link: vm.clientPortal.announcement_link ? vm.clientPortal.announcement_link : '',
                    announcement_text_color: vm.clientPortal.announcement_text_color ? vm.clientPortal.announcement_text_color : '#000',
                    announcement_link_color: vm.clientPortal.announcement_link_color ? vm.clientPortal.announcement_link_color : '#2F7FED',
                    announcement_title_text_color: vm.clientPortal.announcement_title_text_color ? vm.clientPortal.announcement_title_text_color : '#000',
                    has_welcome_video: vm.clientPortal.has_welcome_video ? vm.clientPortal.has_welcome_video : 0,
                    welcome_video_type: vm.clientPortal.welcome_video_type ? vm.clientPortal.welcome_video_type : 1,
                    welcome_video_title: vm.clientPortal.welcome_video_title ? vm.clientPortal.welcome_video_title : '',
                    welcome_title_text_color: vm.clientPortal.welcome_title_text_color ? vm.clientPortal.welcome_title_text_color : '#000',
                    welcome_video_link: vm.clientPortal.welcome_video_link ? vm.clientPortal.welcome_video_link : '',
                    welcome_video_embed_code: vm.clientPortal.welcome_video_embed_code ? vm.clientPortal.welcome_video_embed_code : '',
                    playbook_tab: vm.clientPortal.playbook_tab ? vm.clientPortal.playbook_tab : 1,
                    page_tab: vm.clientPortal.page_tab ? vm.clientPortal.page_tab : 1,
                    form_tab: vm.clientPortal.form_tab ? vm.clientPortal.form_tab : 1,
                    calendar_tab: vm.clientPortal.calendar_tab ? vm.clientPortal.calendar_tab : 1,
                    progress_tab: vm.clientPortal.progress_tab ? vm.clientPortal.progress_tab : 1,
                    workout_tab: vm.clientPortal.workout_tab ? vm.clientPortal.workout_tab : 0,
                    habit_score_color: vm.clientPortal.habit_score_color ? vm.clientPortal.habit_score_color : '#2F7FED',
                    progress_score_color: vm.clientPortal.progress_score_color ? vm.clientPortal.progress_score_color : '#F2A31D',
                    login_score_color: vm.clientPortal.login_score_color ? vm.clientPortal.login_score_color : '#95D7EF',
                    referral_score_color: vm.clientPortal.referral_score_color ? vm.clientPortal.referral_score_color : '#999999',
                    btn_color: vm.clientPortal.btn_color ? vm.clientPortal.btn_color : '#2F7FED',
                    btn_txtcolor: vm.clientPortal.btn_txtcolor ? vm.clientPortal.btn_txtcolor : '#FFF',
                    login_layout: vm.clientPortal.login_layout ? vm.clientPortal.login_layout : 1,
                    login_title: vm.clientPortal.login_title ? vm.clientPortal.login_title : 'Secure Client Portal',
                    btn_text: vm.clientPortal.btn_text ? vm.clientPortal.btn_text : 'Sign In',
                    display_login_card: vm.clientPortal.display_login_card ? vm.clientPortal.display_login_card : 0,
                    thumbnail_type: vm.clientPortal.thumbnail_type ? vm.clientPortal.thumbnail_type : 1,
                    login_card: vm.clientPortal.login_card ? vm.clientPortal.login_card : '',
                    has_login_video: vm.clientPortal.has_login_video ? vm.clientPortal.has_login_video : 0,
                    thumbnail: (vm.clientPortal.thumbnail && typeof JSON.parse(vm.clientPortal.thumbnail) == 'object' && Object.values(JSON.parse(vm.clientPortal.thumbnail)).length) ? JSON.parse(vm.clientPortal.thumbnail) : {},
                    chat_client_bubble_bg_color: vm.clientPortal.chat_client_bubble_bg_color ? vm.clientPortal.chat_client_bubble_bg_color : '#FFF',
                    chat_client_bubble_text_color: vm.clientPortal.chat_client_bubble_text_color ? vm.clientPortal.chat_client_bubble_text_color : '#000',
                    chat_coach_bubble_bg_color: vm.clientPortal.chat_coach_bubble_bg_color ? vm.clientPortal.chat_coach_bubble_bg_color : '#2c3e50',
                    chat_coach_bubble_text_color: vm.clientPortal.chat_coach_bubble_text_color ? vm.clientPortal.chat_coach_bubble_text_color : '#FFF',
                    chat_has_coach_image: vm.clientPortal.chat_has_coach_image ? vm.clientPortal.chat_has_coach_image : 0,
                    chat_has_coach_custom_image: vm.clientPortal.chat_has_coach_custom_image ? vm.clientPortal.chat_has_coach_custom_image : 0,
                    chat_coach_image: vm.clientPortal.chat_coach_image ? vm.clientPortal.chat_coach_image : '',
                    has_facebook: vm.clientPortal.has_facebook ? vm.clientPortal.has_facebook : 1,
                    has_twitter: vm.clientPortal.has_twitter ? vm.clientPortal.has_twitter : 1,
                    has_instagram: vm.clientPortal.has_instagram ? vm.clientPortal.has_instagram : 1,
                    footer_facebook: vm.clientPortal.footer_facebook ? vm.clientPortal.footer_facebook : '',
                    footer_twitter: vm.clientPortal.footer_twitter ? vm.clientPortal.footer_twitter : '',
                    footer_instagram: vm.clientPortal.footer_instagram ? vm.clientPortal.footer_instagram : '',
                    footer_company: vm.clientPortal.footer_company ? vm.clientPortal.footer_company : '',
                    footer_terms: vm.clientPortal.footer_terms ? vm.clientPortal.footer_terms : '',
                    footer_policy: vm.clientPortal.footer_policy ? vm.clientPortal.footer_policy : '',
                    delivery_sms: vm.clientPortal.delivery_sms ? vm.clientPortal.delivery_sms : '',
                    seo_fav: vm.clientPortal.seo_fav && vm.clientPortal.seo_fav != "null"  ? vm.clientPortal.seo_fav : '',
                    seo_share_title: vm.clientPortal.seo_share_title ? vm.clientPortal.seo_share_title : 'Welcome To Your Client Portal',
                    seo_share_desc: vm.clientPortal.seo_share_desc ? vm.clientPortal.seo_share_desc : 'This is your online client portal where you can access all your training.',
                    seo_thumb: vm.clientPortal.seo_thumb && vm.clientPortal.seo_thumb != "null"  ? vm.clientPortal.seo_thumb : '',
                    seo_title: vm.clientPortal.seo_title ? vm.clientPortal.seo_title : 'Welcome To Your Client Portal',
                    seo_keyword: vm.clientPortal.seo_keyword ? vm.clientPortal.seo_keyword : 'Client portal',
                    seo_desc: vm.clientPortal.seo_desc ? vm.clientPortal.seo_desc : 'This is your online client portal where you can access all your training.',
                    seo_index: vm.clientPortal.seo_index ? vm.clientPortal.seo_index : '',
                    fb_pixel: vm.clientPortal.fb_pixel ? vm.clientPortal.fb_pixel : '',
                    chat_background_color: vm.clientPortal.chat_background_color ? vm.clientPortal.chat_background_color : '#F7F7F7',
                    has_chatbox: vm.clientPortal.has_chatbox ? vm.clientPortal.has_chatbox : 0,
                    portal_chat_email_notification: vm.clientPortal.portal_chat_email_notification ? vm.clientPortal.portal_chat_email_notification : 0,
                    pinned_widget: vm.clientPortal.pinned_widget ? vm.clientPortal.pinned_widget : 0,
                    has_footer: vm.clientPortal.has_footer ? vm.clientPortal.has_footer : 0,
                    chat_placement: vm.clientPortal.chat_placement ? vm.clientPortal.chat_placement : 'right',
                    chat_button_bg_color: vm.clientPortal.chat_button_bg_color ? vm.clientPortal.chat_button_bg_color : '#2c3e50',
                    chat_button_text_color: vm.clientPortal.chat_button_text_color ? vm.clientPortal.chat_button_text_color : '#fff',
                    footer_textcolor: vm.clientPortal.footer_textcolor ? vm.clientPortal.footer_textcolor : '#2F7FED',
                    footer_bgcolor: vm.clientPortal.footer_bgcolor ? vm.clientPortal.footer_bgcolor : '#f5f5f5',
                    has_habit_score: vm.clientPortal.has_habit_score == undefined ? 1 : vm.clientPortal.has_habit_score,
                    has_progress_score: vm.clientPortal.has_progress_score == undefined ? 1 : vm.clientPortal.has_progress_score,
                    has_portal_login: vm.clientPortal.has_portal_login == undefined ? 1 : vm.clientPortal.has_portal_login,
                    has_referrals: vm.clientPortal.has_referrals == undefined ? 1 : vm.clientPortal.has_referrals,
                    display_cover: vm.clientPortal.display_cover ? vm.clientPortal.display_cover : 0,
                    headline: vm.clientPortal.headline ? vm.clientPortal.headline : '',
                    description: vm.clientPortal.description ? vm.clientPortal.description : '',
                    cover_type: vm.clientPortal.cover_type ? vm.clientPortal.cover_type : 0,
                    cover: vm.clientPortal.cover ? vm.clientPortal.cover : '',
                    has_small_screen_cover: vm.clientPortal.has_small_screen_cover ? vm.clientPortal.has_small_screen_cover : 0,
                    small_cover: vm.clientPortal.small_cover ? vm.clientPortal.small_cover : '',
                    has_cover_overlay: vm.clientPortal.has_cover_overlay ? vm.clientPortal.has_cover_overlay : 0,
                    overlay_color: vm.clientPortal.overlay_color ? vm.clientPortal.overlay_color : '',
                    overlay_opacity: vm.clientPortal.overlay_opacity ? vm.clientPortal.overlay_opacity : 20,
                    cover_background_color: vm.clientPortal.cover_background_color ? vm.clientPortal.cover_background_color : '#2F7FED',
                    display_footer: vm.clientPortal.display_footer ? vm.clientPortal.display_footer : 0,
                    activity_widget: vm.clientPortal.activity_widget ? vm.clientPortal.activity_widget : 0,
                    opac_overlay_color: vm.clientPortal.opac_overlay_color ? vm.clientPortal.opac_overlay_color : 'rgb(0, 0, 0, 0.05)',
                    delivery_sms_media: vm.clientPortal.delivery_sms_media ? JSON.parse(vm.clientPortal.delivery_sms_media) : [],
                    company_branding: vm.clientPortal.company_branding ? vm.clientPortal.company_branding : '',
                    header_bgcolor: vm.clientPortal.header_bgcolor ? vm.clientPortal.header_bgcolor : '',
                    header_textcolor: vm.clientPortal.header_textcolor ? vm.clientPortal.header_textcolor : '',
                    logo: vm.clientPortal.logo ? vm.clientPortal.logo : '',
                    author: vm.clientPortal.author ? vm.clientPortal.author : '',
                };

                vm.deliveryEmail = {
                    defaultHandler: true,
                    subject: vm.clientPortal.delivery_subject,
                    email: vm.clientPortal.delivery_email,
                    is_email_logo: vm.clientPortal.show_email_logo,
                    is_signature: vm.clientPortal.show_email_signature,
                    signature_id: vm.clientPortal.email_signature,
                    show_email_logo: vm.clientPortal.show_email_logo,
                    email_logo: vm.clientPortal.email_logo,
                    email_button_bg_color: vm.clientPortal.email_button_bg_color,
                    email_button_text_color: vm.clientPortal.email_button_text_color,
                    module_id: vm.clientPortal.id,
                };
            },

            handleDefaultEmail () {
                const vm = this;
                let email = '<p><strong>Hi {{first_name}}!</strong></p>';
                    email += '<p>A new client portal has been created for you by {{company_owner}}.</p>';
                    email += '<p>In your {{company_name}} client portal, you can access the resources you need to succeed!</p>';
                    email += '<p>Inside you can learn, ask questions, and more!</p>';
                    email += '<p><br></p>';
                    email += '<p class="text-center">{{auto_login_link}}</p>';
                    email += '<p><br></p>';
                    email += "<p>If you ever get logged out don\'t worry!</p>";
                    email += '<p><br></p>';
                    email += '<p><strong>Here is the link to your client portal:</strong></p>';
                    email += '<p>{{playbook_url}}</p>';
                    email += '<p>User ID:  {{user_name}} </p>';
                    email += '<p>Password: {{password}} </p>';
                    email += '<br>';
                    email += '<p>Thanks for being awesome {{first_name}}!</p>';
                    email += '<p>Team {{company_name}}</p>';
                    email += '<p><br></p>';
                    email += '<p><b>Agreement to copyright:</b> By accessing this information you agree to all copyrights by {{company_name}} to this intellectual property and agree not to duplicate, redistribute, or alter this information in any manner without written permission.</p>';

                vm.deliveryEmail.defaultEmail  = true;
                vm.deliveryEmail.subject  = '[ACCESS] Your New Client Portal!';
                vm.deliveryEmail.email    = email;
            },

            handleDefaultSms () {
                const vm = this;

                vm.form.delivery_sms = '{{company_owner}} has just created a new client portal for you.\r\nClick here to log right in! {{auto_login_sms_link}}.\r\nThe details have also been sent to your email.Cheers!\r\nTeam {{company_name}}';
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));
                params.setFieldError            = setFieldError;
                params.delivery_subject         = vm.deliveryEmail.subject;
                params.delivery_email           = vm.deliveryEmail.email;
                params.show_email_logo          = vm.deliveryEmail.show_email_logo;
                params.show_email_signature     = vm.deliveryEmail.is_signature;
                params.email_signature          = vm.deliveryEmail.signature_id;
                params.email_logo               = vm.deliveryEmail.email_logo;
                params.email_button_bg_color    = vm.deliveryEmail.email_button_bg_color,
                params.email_button_text_color  = vm.deliveryEmail.email_button_text_color,

                vm.updatePortalSetting(params);
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                })
            },

            validLink (str) {
                const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

                return !!pattern.test(str);
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            handlePreview (preview) {
                const vm = this;
                const form = vm.$refs['client-portal-setting-form'];
                const params = JSON.parse(JSON.stringify(vm.form));
                params.setFieldError = form.setFieldError;
                vm.updatePortalSetting(params);
            },

            goTo (refName) {
                const vm = this;

                let element = vm.$refs[refName];

                if (element) {
                    let top = element.offsetTop;

                    setTimeout(function() {
                        document.querySelector('.preview_content .content_area').scrollTo(0, top);
                    }, 100);
                }
            },

            setting (refName) {
                const vm = this;
                let element = vm.$refs[refName];

                if (refName === 'ftrsetting') {
                    vm.tab = 'footer';
                }

                if (element) {
                    let top = element.offsetTop;
                    document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                }
            },

            updateSmsText () {
                const vm = this;

                const clientPortalSms = vm.$refs['client-portal-sms-component'];

                if (clientPortalSms) {
                    vm.clientPortalSmsText = clientPortalSms.smsMedia && clientPortalSms.smsMedia.media && clientPortalSms.smsMedia.media[0] ? vm.form.delivery_sms + '\n\n' + clientPortalSms.smsMedia.media[0].toString() : vm.form.delivery_sms;
                }
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },
        },
    }
</script>

<style scoped>
    .global_setting .preview_content{
        width: 40%;
    }
    .client-portal-setting-form{
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    :deep(.color_container .input_label) {
        color: #121525;
        font-weight: 400;
        margin: 0 0 5px 0;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dashboard .banner {
        height: auto;
        line-height: 0;
        position: relative;
    }

    .dashboard .banner .banner_img {
        /* border-radius: 0 0 5px 5px; */
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .banner_wpr .banner_img{
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
        object-fit: cover;
    }

    .dashboard .banner .bnr_content, .banner_wpr .bnr_content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        z-index: 2;
        padding: 0 15px;
    }

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .dashboard .banner .bnr_content h4 {
        /* font-size: 15px !important;
        line-height: 20px !important; */
        color: #fff;
        /* font-weight: 400 !important; */
        /* text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    }

    .dashboard .banner .bnr_content h1 {
        /* font-size: 30px !important;
        line-height: 40px !important; */
        color: #fff;
        /* font-weight: 500 !important; */
        /* text-shadow: 0 4px 4px rgba(0, 0, 0, 0.6); */
    }

    .dashboard .dash_tabs {
        text-align: center;
        margin-top: -20px;
    }

    .dashboard .dash_tabs .tab_btn{
        margin: 0 10px;
    }
    .dashboard .dash_tabs .tab_btn li {
        font-size: 8px;
        line-height: 10px;
        padding: 11px 7px;
    }

    .dashboard .dash_tabs .tab_btn li:first-child {
        font-size: 12px;
        padding: 11px 7px;
        flex: 0 1 40px;
    }

    .dashboard .score_circle {
        width: 40px;
        height: 40px;
        padding: 3px;
    }
    .dashboard .score_circle .inner_circle .score{
        font-size: 10px;
        line-height: 14px;
    }
    .dashboard .score_circle .inner_circle .score span{
        font-size: 10px;
    }
    .dashboard .activity_score{
        padding: 15px 0 10px 0;
        justify-content: space-around;
    }
    .dashboard .activity_score li{
        flex: 0 0 25%;
    }
    .dashboard .activity_score li h5 {
        margin-top: 10px;
        font-size: 11px;
        line-height: 14px;
    }

    .dashboard h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }
    .preview_content {
        position: relative;
        width: 40%;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }
    .preview_content.show {
        margin-right: 0;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 2px;
    }
    .preview_content .cell{
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
    }
    .preview_content .cell:after, .preview_content .cell:before{
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_content .cell:before{
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }
    .preview_content .cell:after{
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }
    .preview_content .cell .content_area{
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .preview_content .banner_area{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .preview_content .banner_area .banner_wpr{
        position: relative;
        height: auto;
        background: #f5f5f5;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .preview_content .banner_area .banner_wpr picture{
        width: 100%;
    }
    .preview_content .banner_area .banner_wpr .bnr_content{
        transform: none;
        top: auto;
        left: auto;
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .preview_content .banner_area2, .preview_content .banner_area3{
        height: 100%;
    }
    .preview_content .banner_area3{
        display: flex;
        flex-direction: column;
    }
    .preview_content .banner_area2 .banner_wpr{
        position: relative;
        background: #f5f5f5;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        min-height: 100%;
    }
    .preview_content .banner_area2 .banner_wpr h4, .preview_content .banner_area2 .banner_wpr h1{
        text-align: center;
    }
    .preview_content .banner_area3 .banner_wpr{
        position: relative;
        background: #f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        flex-grow: 1;
    }
    .banner_area3 .banner_wpr .banner_img{
        height: 100%;
        object-fit: cover;
        border-radius: 0;
    }
    .preview_content .banner_wpr .bnrCont_wpr{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        /* margin-bottom: 30px; */
    }
    .preview_content .banner_area3 .banner_wpr .bnrCont_wpr{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 2;
    }
    .preview_content .banner_wpr h1{
        font-weight: 600;
        color: rgb(255, 255, 255);
        /* text-shadow: 0 1px 4px rgba(0,0,0,0.6); */
        /* text-transform: uppercase; */
        z-index: 2;
    }
    .preview_content .banner_wpr h4{
        font-weight: 600;
        color: rgb(255, 255, 255);
        /* text-shadow: 0 1px 3px rgba(0,0,0,0.5); */
        /* text-transform: uppercase; */
        z-index: 2;
    }
    .preview_content .banner_area .login{
        position: relative;
        margin-top: -20px;
        padding: 30px;
        width: 355px;
        box-shadow: 0px 4px 35px rgba(0,0,0,0.1);
        z-index: 3;
        margin-bottom: 40px;
        border-radius: 8px;
    }
    .preview_content .banner_area2 .login{
        padding: 30px;
        width: 100%;
        box-shadow: 0px 4px 35px rgba(0,0,0,0.1);
        border-radius: 8px;
        position: relative;
        z-index: 1;
        background: #fff;
        max-width: 300px;
        margin: 20px auto;
    }
    .preview_content .banner_area3 .login{
        padding: 25px 20px;
        width: 100%;
        box-shadow: 0px 4px 35px rgba(0,0,0,0.1);
        background: #fff;
        position: relative;
        z-index: 1;
    }
    .preview_content .banner_area .login h2, .preview_content .banner_area2 .login h2, .preview_content .banner_area3 .login h2{
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    /* .preview_content .banner_area .login .form_grp, .preview_content .banner_area2 .login .form_grp, .preview_content .banner_area3 .login .form_grp{
        padding: 7px 0;
    } */
    .preview_content .banner_area .login .form_grp .input_label, .preview_content .banner_area2 .login .form_grp .input_label, .preview_content .banner_area3 .login .form_grp .input_label{
        font-size: 13px;
        line-height: 17px;
    }
    .preview_content .banner_area .login .form_grp .field_wpr input, .preview_content .banner_area2 .login .form_grp .field_wpr input, .preview_content .banner_area3 .login .form_grp .field_wpr input{
        height: 40px;
        font-size: 13px;
    }
    .preview_content .banner_area .login .group-col-2 label.checkbox span, .preview_content .banner_area2 .login .group-col-2 label.checkbox span, .preview_content .banner_area3 .login .group-col-2 label.checkbox span{
        width: 12px;
        height: 12px;
        flex: 0 0 10px;
    }
    .preview_content .banner_area .login .group-col-2 label, .preview_content .banner_area .login .group-col-2 .forgot a, .preview_content .banner_area2 .login .group-col-2 label, .preview_content .banner_area2 .login .group-col-2 .forgot a, .preview_content .banner_area3 .login .group-col-2 label, .preview_content .banner_area3 .login .group-col-2 .forgot a{
        font-size: 11px;
        line-height: 13px;
    }
    .preview_content .banner_area .login .primary_btn, .preview_content .banner_area2 .login .primary_btn, .preview_content .banner_area3 .login .primary_btn{
        font-size: 16px;
        line-height: 20px;
        height: 45px;
        letter-spacing: 2px;
        margin-bottom: 5px;
    }
    .preview_content .login .login-thumbnail .img_wpr img{
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    .preview_content .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }
    .preview_content .dashboard_footer ul li{
        padding: 2px;
    }
    .preview_content .dashboard_footer ul li a {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }
    .preview_content .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }
    .preview_content .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }
    .preview_content .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }
    .preview_content .feature_area{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .preview_content .feature_area .left_side{
        width: 100%;
        padding: 0 10px;
    }
    .preview_content .feature_area .right_side{
        width: 100%;
        padding: 0 10px;
    }
    .preview_content .feature_area .right_side .extra_info{
        padding: 10px 15px;
        font-size: 10px;
        line-height: 14px;
        border: 0;
    }
    .preview_content .feature_area .right_side .extra_info .action_wpr{
        position: relative;
        margin-bottom: -5px;
    }
    .preview_content .feature_area .right_side .extra_info h6 a{
        font-size: 10px;
        line-height: 12px;
    }
    .preview_content .full_width .extra_info{
        padding: 10px 15px;
        font-size: 10px;
        line-height: 14px;
        border: 0;
    }
    .preview_content .full_width .extra_info .action_wpr{
        position: relative;
        margin-bottom: -5px;
    }
    .preview_content .full_width .extra_info h6 a{
        font-size: 10px;
        line-height: 12px;
    }
    .preview_content .client_productList{
        padding: 0 10px;
    }
    .preview_content .client_productList li{
        padding: 15px 0;
    }
    /* .preview_content .client_productList .product_thumb{
        height: 80px;
    } */
    .preview_content .client_productList .product_card{
        position: relative;
        overflow: visible;
    }
    .preview_content .client_productList .product_card .product_cont{
        padding: 15px 20px 25px 20px;
        border-radius: 0 0 10px 10px;
    }
    .preview_content .client_productList .product_card .product_cont h4{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 15px;
        max-height: 35px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .preview_content .client_productList .product_card .product_cont p{
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 25px;
        max-height: 45px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .preview_content .client_productList .product_card button.pin{
        right: -10px;
        top: -10px;
    }
    .preview_content .client_productList .product_card .product_cont .primary_btn{
        font-size: 13px;
        line-height: 20px;
        height: 40px;
        border-radius: 4px;
        letter-spacing: 0.4px;
    }
    .preview_content .chat{
        line-height: 0;
        height: 0 ;
        border-radius: 20px 20px 0 0;
        padding: 0 15px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 15px rgba(0,0,0,0.05);
        transition: all 0.3s ease-in-out;
    }
    .preview_content .chat.show{
        bottom: 0;
        height: 450px;
    }
    .chat .chat_user .msg p, .chat .chat_client .msg p{
        padding: 10px 15px;
        font-size: 10px;
        line-height: 14px;
    }
    .chat_btn{
        position: absolute;
        width: 100%;
        height: 24px;
        border-radius: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
    }
    .chat_close{
        position: relative;
        left: 50%;
        margin-top: 0;
        transform: translateX(-50%);
        padding: 0 15px;
        border-radius: 0 0 6px 6px;
        font-size: 10px;
        cursor: pointer;
        width: auto;
        height: 12px;
    }
    .seo_card{
        max-width: 450px;
        border: 1px solid #f5f5f5;
        box-shadow: 0 1px 15px rgba(0,0,0,0.05);
        border-radius: 12px;
        overflow: hidden;
        line-height: 0;
        margin:12px 0;
    }
    .seo_card img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .seo_card .seo_info{
        padding: 25px 30px;
        background: #fff;
    }
    .seo_card .seo_info h3{
        font-size: 17px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: capitalize;
    }
    .seo_card .seo_info p{
        font-size: 12px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    :deep(.delivery-message .group_item) {
        width: 100%;
    }

    /* :deep(.delivery-message .subject-emoji-picker) {
        margin-top: -20px;
    } */

    :deep(.swiper-wrapper .swiper-slide) {
        min-width: 120px;
    }

    .login_cover {
        margin: 0;
        padding: 0;
        background: inherit;
        border-top: 0;
        border-bottom: 1px solid #e7e7e7;
    }

    .content_area .banner_area .banner_wpr .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        border-radius: 0;
    }

    .content_area .banner_area .banner_wpr h1,
    .content_area .banner_area .banner_wpr h4 {
        font-weight: 600;
        color: #fff;
        position: relative;
        z-index: 3;
    }

    .login_cover_bg {
        background: #f5f5f5;
    }

    :deep(.login-thumbnail) {
        width: 100%;
        margin-bottom: 10px;
    }
    .extra_note{
        font-size: 30px;
        line-height: 40px;
        font-weight: 300;
        padding: 30px 70px;
        border: 1px solid #e9e9e9;
        color: #5a5a5a;
        border-radius: 10px;
        margin: 200px auto 0 auto;
    }
    .browser_layout{
        background: #f5f5f5;
        border-radius: 5px;
        padding: 5px 0 2px 0;
        max-width: 450px;
        margin: 12px 0;
    }
    .browser_tabs{
        display: flex;
        padding: 0 10px;
    }
    .browser_tabs li{
        padding: 7px 10px;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0 0;
        background: #fff;
        flex: 0 0 150px;
        position: relative;
    }
    .browser_tabs li:before{
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 0 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        left: -8px;
    }
    .browser_tabs li:after{
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 8px 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        right: -8px;
    }
    .browser_tabs li h6{
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .browser_tabs li i{
        margin-left: auto;
        font-size: 10px;
        color: #5a5a5a;
    }
    .browser_tabs li img{
        max-width: 15px;
        height: auto;
        margin-right: 7px;
    }
    .browser_tabs li:last-child{
        flex: 0 0 auto;
        background: transparent;
        padding: 10px;
        position: static;
    }
    .browser_tabs li:last-child i{
        color: #121525;
    }
    .browser_info{
        display: flex;
        align-items: center;
        background: #fff;
        padding: 5px;
        margin: 0 2px;
        border-radius: 3px;
    }
    .browser_info i{
        font-size: 10px;
        margin: 0 5px;
    }
    .browser_info img.user{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }
    .browser_search{
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 6px;
        border-radius: 12px;
        margin: 0 10px;
        flex: 1;
    }
    .browser_search p, .browser_search i{
        font-size: 9px;
        line-height: 11px;
    }
    .google_ranking{
        background: #fff;
        border-radius: 10px;
        max-width: 450px;
        padding: 30px;
        margin: 12px 0;
        box-shadow: 0 1px 15px rgba(0,0,0,0.15);
    }
    .google_ranking h6{
        font-size: 12px;
        line-height: 14px;
        color: #70757a;
        font-weight: 400;
        margin-bottom: 5px;
    }
    .google_ranking h4{
        font-size: 15px;
        line-height: 20px;
        color: #1a0dab;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .google_ranking P{
        font-size: 12px;
        line-height: 17px;
        color: #4d5156;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .msg_preview{
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }
    .sms_preview{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }
    .global_setting .msg_preview:after, .global_setting .sms_preview:after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .global_setting .sms_preview:after{
        right: 30px;
        left: auto;
    }
    .msg_preview p{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .msg_preview p span{
        font-size: 14px;
        line-height: 19px;
        display: block;
        padding-bottom: 3px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msg_preview h2{
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msgFooter p{
        font-size: 11px;
        line-height: 17px;
    }
    .cell .msgFooter p a{
        text-decoration: none;
    }
    .sender_info{
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }
    .sender_info img{
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
    .sender_info .info{
        margin: 0;
    }
    .sender_info .info h5{
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }
    .sender_info .info h6{
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }
    .sender_info .info h6 span{
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    .cover_type{
        margin: 10px 0 0;
        padding: 0;
        background: transparent;
    }
    .content_area.neg_margin{
        margin: 0 -50px;
        padding: 0;
    }
    .area_wpr{
        border-bottom: 1px solid #eeeeee;
        padding: 20px 50px 40px;
    }
    .area_wpr:last-of-type{
        border: 0;
    }

    .email-preview,
    :deep(.redactor-styles.redactor-in.email-preview p) {
        font-size: 11px !important;
        line-height: 15px !important;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 10px;
    }

    :deep(.videoWrapper) {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        /* padding-top: 25px; */
        margin-bottom: 10px;
    }

    :deep(.videoWrapper iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .client_card .card_header {
        padding: 15px 20px;
    }

    .client_card .card_header h4 {
        font-size: 12px;
        line-height: 15px;
    }

    .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 13px;
    }

    .client_card .card_header .user_info {
        margin-left: 10px;
    }

    .client_card .card_header .user_info span {
        font-size: 10px;
        line-height: 12px;
    }
    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    .banner_cover2.cover-image-sm{
        min-height: 75px;
    }

    .preview_content .banner_area3 .banner_wpr[data-v-3cc2c7bc] {
        flex-grow: unset;
    }
    .sliderWpr{
        max-width: 280px;
        margin: 0 auto;
    }
</style>
